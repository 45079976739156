import { useMutation } from 'react-query'

import { notification } from 'antd'
import { AxiosInstance, AxiosResponse } from 'axios'
import { BASE_API_URL } from 'constant'
import { pick, toUpper } from 'lodash'
import { getLocalTimeZoneName } from 'v2source/tools/formatter'
import { z } from 'zod'

import { ApiErrorResponse } from './core.types'
import { validateDataSchema } from './dataValidations'

export function downloadFileWithDOM({
  href,
  filename,
}: {
  href: string
  filename: string
}) {
  const link = document.createElement('a')
  link.setAttribute('href', href)
  link.setAttribute('download', filename)
  document.body.appendChild(link)
  link.click()
}

function generateBlobBytesWithAtob(data: any) {
  const byteCharacters = atob(data)
  const byteNumbers = new Array(byteCharacters.length)
  for (let i = 0; i < byteCharacters.length; i += 1) {
    byteNumbers[i] = byteCharacters.charCodeAt(i)
  }
  return new Uint8Array(byteNumbers)
}

export async function apiResponseWrapper<
  Response extends ApiService.DefaultResponse,
>(
  api: Promise<AxiosResponse<Response>>,
  { showErrorNotification = false }: ApiService.DefaultRequestConfig = {},
): Promise<Response & ApiService.ResponseError> {
  return api
    .then((resp) => {
      if (resp.data?.error) {
        throw new Error(
          resp.data?.error?.data?.message || 'Internal Server Error',
        )
      }
      return resp.data
    })
    .catch((error) => {
      const err = new ApiErrorResponse(error)
      if (showErrorNotification && (!err.message || err.messageIsString)) {
        notification.error({
          key: 'notification-error-api-wrapper',
          message: err.message || 'Internal server error',
        })
      }
      // If errMsg is an object or array, throw error as its so that errMsg can be read again at catch as object
      throw err
    })
}
apiResponseWrapper.Blob = async function apiBlobResponseWrapper<
  Response extends ApiService.DefaultResponse & Blob,
>(
  api: Promise<AxiosResponse<Response>>,
  {
    showErrorNotification = true,
    filename,
    fileType,
    useAtob,
    previewOnly,
  }: ApiService.DefaultRequestConfig & ApiService.DefaultParamsFileDownload,
) {
  return api
    .then(async (resp) => {
      try {
        // Check if response json
        const responseJSON = JSON.parse(await resp.data.text())
        if (responseJSON?.error?.data?.message) {
          notification.error({
            message: responseJSON?.error?.data?.message,
          })
        }
      } catch {
        const urlObject = URL.createObjectURL(
          new Blob(
            [useAtob ? generateBlobBytesWithAtob(resp.data) : resp.data],
            {
              type: fileType,
            },
          ),
        )
        if (previewOnly) {
          return window.open(urlObject)
        }
        downloadFileWithDOM({ href: urlObject, filename })
        URL.revokeObjectURL(urlObject)
      }
    })
    .catch((error) => {
      if (showErrorNotification) {
        notification.error({
          message:
            error?.response?.data?.message ||
            error?.message ||
            'Internal server error',
        })
      }
      throw new Error(
        error?.response?.data?.message ||
          error?.message ||
          'Internal server error',
        {
          cause: error,
        },
      )
    })
}

export type GenerateCrupApiConfig = {
  lang?: string
  onParams?: <T = any>(
    params?: ApiService.DefaultSearchParams<T>,
  ) => ApiService.DefaultSearchParams<T>
}

type DefaultDeleteMutationVariables =
  | number
  | number[]
  | { ids: number | number[]; kwargs?: { [x: string]: any }; args?: any[] }

export function generateCrudApi(
  api: AxiosInstance,
  model: string,
  config?: GenerateCrupApiConfig,
) {
  const { lang } = config || {}

  function buildCustomDelete({
    path,
    spreadIds,
  }: {
    path: string
    spreadIds?: boolean
  }) {
    return <T = any>(
      variables: DefaultDeleteMutationVariables,
      options?: ApiService.DefaultRequestConfig,
    ) => {
      const {
        ids,
        kwargs: optKwargs = {},
        args: customArgs = [],
      } = typeof variables === 'number' || Array.isArray(variables)
        ? {
            ids: variables,
            kwargs: undefined as undefined | { [x: string]: any },
            args: undefined as undefined | any[],
          }
        : variables
      const { headers, kwargs } = options || {}
      return apiResponseWrapper<ApiService.DefaultResponse<T>>(
        api.post(
          `/dataset/${model}/${path}/`,
          {
            params: {
              args:
                spreadIds && Array.isArray(ids) ? ids : [ids, ...customArgs],
              kwargs: {
                context: {
                  lang,
                },
                ...kwargs,
                ...optKwargs,
              },
            },
          },
          {
            headers,
          },
        ),
        options,
      )
    }
  }

  return {
    search: <T = any>(
      params?: ApiService.DefaultSearchParams<T>,
      options?: ApiService.DefaultRequestConfig,
    ) => {
      const { headers } = options || {}
      let newParams = params
      if (config?.onParams) {
        newParams = config?.onParams?.(params)
      }
      return apiResponseWrapper<
        ApiService.DefaultResponse<ApiService.SearchResultData<T>>
      >(
        api.post(
          `/dataset/${model}/search_read/`,
          {
            params: {
              context: { lang },
              ...pick(newParams, ['offset', 'limit', 'domain', 'fields', 'id']),
              sort: Array.isArray(newParams?.sort)
                ? newParams.sort.join(',')
                : newParams?.sort ?? 'id desc',
            },
            kwargs: {
              context: { lang: newParams?.lang || lang },
            },
          },
          { headers },
        ),
        options,
      )
    },
    get: <
      T = any,
      IdType extends ApiService.DefaultGetRequestIdInputType = number,
    >(
      id: IdType,
      params?: ApiService.DefaultSearchParams<
        ApiService.DefaultGetRequestResult<IdType, T>
      >,
      options?: ApiService.DefaultRequestConfig,
    ) => {
      const { headers } = options || {}
      let newParams = params
      if (config?.onParams) {
        newParams = config?.onParams?.(params)
      }
      return apiResponseWrapper<
        ApiService.DefaultResponse<
          ApiService.DefaultGetRequestResult<IdType, T>
        >
      >(
        api.post(
          `/dataset/${model}/read/`,
          {
            params: {
              args: [id, newParams?.fields],
              kwargs: {
                context: { lang: newParams?.lang || lang },
                ...newParams?.kwargs,
              },
            },
          },
          { headers },
        ),
        options,
      ).then((resp) => {
        if (Array.isArray(resp.result) && typeof id === 'number')
          return { ...resp, result: resp.result?.[0] } as typeof resp
        return resp
      })
    },
    readGroup: <T = any>(
      params?: ApiService.DefaultSearchReadGroupParams<T>,
      options?: ApiService.DefaultRequestConfig,
    ) => {
      const { headers } = options || {}
      let newParams = params
      if (config?.onParams) {
        newParams = config?.onParams?.(params)
      }
      return apiResponseWrapper<ApiService.DefaultResponse<T[]>>(
        api.post(
          `/dataset/${model}/read_group/`,
          {
            params: {
              args: [],
              kwargs: {
                ...params,
                context: { lang: newParams?.lang || lang },
                ...newParams?.kwargs,
                lazy: params?.lazy ?? true,
              },
            },
          },
          { headers },
        ),
        options,
      )
    },
    create: <T = any, Input = any>(
      data: Input,
      options?: ApiService.DefaultRequestConfig & {
        kwargs?: Record<string, any>
      },
    ) => {
      const { headers, kwargs } = options || {}
      return apiResponseWrapper<ApiService.DefaultResponse<T>>(
        api.post(
          `/dataset/${model}/create/`,
          {
            params: {
              args: [data],
              kwargs: {
                ...kwargs,
                context: {
                  lang,
                  ...(kwargs?.context || {}),
                },
              },
            },
          },
          {
            headers,
          },
        ),
        options,
      )
    },
    write: <T = any, Input = any>(
      id: number | number[],
      data: Input,
      options?: ApiService.DefaultRequestConfig,
    ) => {
      const { headers, kwargs } = options || {}
      return apiResponseWrapper<ApiService.DefaultResponse<T>>(
        api.post(
          `/dataset/${model}/write/`,
          {
            params: {
              args: [id, data],
              kwargs: {
                ...kwargs,
                context: {
                  lang,
                  ...(kwargs?.context || {}),
                },
              },
            },
          },
          {
            headers,
          },
        ),
        options,
      )
    },
    upload_files: <T = any>(
      id: number,
      key: string,
      file: File,
      options?: ApiService.DefaultRequestConfig,
    ) => {
      const { headers } = options || {}
      const formData = new FormData()
      formData.append(key, file)
      return apiResponseWrapper<ApiService.DefaultResponse<T>>(
        api.post(`/dataset/${model}/${id}/upload_files`, formData, { headers }),
        options,
      )
    },
    unlink: buildCustomDelete({ path: 'unlink' }),
    soft_unlink: buildCustomDelete({ path: 'soft_unlink' }),
    soft_delete: buildCustomDelete({ path: 'soft_unlink', spreadIds: true }),
    unarchives: buildCustomDelete({ path: 'unarchives' }),
    restore: buildCustomDelete({ path: 'unarchives', spreadIds: true }),
    archive: buildCustomDelete({ path: 'action_archive' }),
    unarchive: buildCustomDelete({ path: 'action_unarchive' }),
    export_excel: <T = any>(
      { filename, ...params }: ApiService.ExcelDownloadRequestParams<T>,
      options?: ApiService.DefaultRequestConfig,
    ): any => {
      return apiResponseWrapper.Blob(
        api.post(
          `/dataset/${model}/export_excel`,
          {
            params: {
              args: [params || {}],
              kwargs: {
                lang,
                tz: getLocalTimeZoneName(),
              },
            },
          },
          {
            responseType: 'blob',
            ...(options || {}),
          },
        ),
        { filename, fileType: 'application/vnd.ms-excel' },
      )
    },
    download_excel: <T = any>(
      { filename, ...params }: ApiService.ExcelDownloadRequestParams<T>,
      options?: ApiService.DefaultRequestConfig,
    ): any => {
      return apiResponseWrapper.Blob(
        api.post(
          `/dataset/${model}/download_excel`,
          {
            params: {
              args: [],
              kwargs: {
                lang,
                tz: getLocalTimeZoneName(),
                ...params,
              },
            },
          },
          {
            responseType: 'blob',
            ...(options || {}),
          },
        ),
        { filename, fileType: 'application/vnd.ms-excel' },
      )
    },
    request_download_pdf: async ({
      id,
      ...contextPayload
    }: {
      id: number | number[]
      [key: string]: any
    }) => {
      return apiResponseWrapper(
        api.post(`/dataset/${model}/download_pdf`, {
          params: {
            args: [id],
            kwargs: {
              context: {
                lang,
                tz: getLocalTimeZoneName(),
                ...contextPayload,
              },
            },
          },
        }),
      ).then((resp) => {
        // result is the file url
        if (resp.result) {
          // open file url
          window.open(resp.result?.replace(/http/, 'https'), '_blank')
        }
        return resp
      })
    },
    download_pdf: (
      { filename, id }: { filename: string; id: number },
      options?: ApiService.DefaultRequestConfig,
    ) => {
      const downloadUrl = `/${model}/pdf/${id}`
      // const downloadUrl = `${BASE_API_URL}/${model}/pdf/${id}`;

      // /**
      //  * Remove /ja from the url if exists
      //  */
      let url = downloadUrl.replace(/ja\/api/g, 'api')

      // /**
      //  * Force change the protocol to https
      //  */
      if (!BASE_API_URL.includes('odoo-dev')) {
        url = url.replaceAll('http://', 'https://')
      }
      return apiResponseWrapper.Blob(
        api.get(url, {
          responseType: 'blob',
          ...(options || {}),
        }),
        {
          filename: filename.endsWith('.pdf') ? filename : `${filename}.pdf`,
          fileType: 'application/pdf;charset=utf-8',
        },
      )
    },
  }
}

type GenerateApiReturn<T extends string> = Record<Uppercase<T>, string>

const defaultActions = [
  'search_read',
  'read',
  'create',
  'write',
  'unlink',
  'read_group',
  'download_excel',
  'download_pdf',
] as const
type A = typeof defaultActions[number]

export function generateApiRoutes<T extends string>(
  model: ApiService.Available_BE_Models,
  actions: T[] = [],
) {
  return [...actions, ...defaultActions].reduce(
    (acc, action) => ({
      ...acc,
      [toUpper(action)]: `/dataset/${model}/${action}/`,
    }),
    {},
  ) as GenerateApiReturn<A> & GenerateApiReturn<T>
}

export const isError = (object: any): object is ApiService.ResponseError => {
  if (typeof object === 'undefined') {
    return true
  }
  return 'error' in object
}

type GenerateDataMutationDefaultProps = {
  createSchema: z.ZodType
  updateSchema: z.ZodType
  requestConfig?: {
    create?: (data: unknown) => ApiService.DefaultRequestConfig
    write?: (data: unknown) => ApiService.DefaultRequestConfig
  }
}

type CrudApiReturnType = ReturnType<typeof generateCrudApi>

export function generateDataMutationDefaults<
  TData extends ApiService.DefaultResponse = ApiService.DefaultResponse,
  TError = any,
  TVariables = unknown,
>(
  api: CrudApiReturnType,
  {
    createSchema,
    updateSchema,
    requestConfig,
  }: GenerateDataMutationDefaultProps,
) {
  return (props?: ApiService.MutationOptionsDefaultCases) => {
    return {
      createMutation: useMutation<TData, TError, TVariables>({
        mutationFn: (
          data: z.infer<typeof createSchema> | z.infer<typeof createSchema>[],
        ) =>
          api.create<number>(
            validateDataSchema(
              z.union([createSchema, z.array(createSchema)]),
              data,
            ),
            requestConfig?.create?.(data),
          ) as any,
        ...props?.create,
      }),
      writeMutation: useMutation<TData, TError, TVariables>({
        mutationFn: (async (args: z.infer<typeof updateSchema>) => {
          const { id, ...data } = validateDataSchema(updateSchema, args) as any
          return api.write(id, data, requestConfig?.write?.(args))
        }) as any,
        ...props?.update,
      }),
      unlinkMutation: useMutation<
        TData,
        TError,
        DefaultDeleteMutationVariables
      >({
        mutationFn: (variables: DefaultDeleteMutationVariables) => {
          return api.unlink<number>(variables)
        },
        ...(props?.unlink as any),
      }),
      softUnlinkMutation: useMutation<
        TData,
        TError,
        DefaultDeleteMutationVariables
      >({
        mutationFn: (variables: DefaultDeleteMutationVariables) =>
          api.soft_unlink<number>(variables),
        ...(props?.softUnlink as any),
      }),
      softDeleteMutation: useMutation<
        TData,
        TError,
        DefaultDeleteMutationVariables
      >({
        mutationFn: (variables: DefaultDeleteMutationVariables) =>
          api.soft_delete<number>(variables),
        ...(props?.softDelete as any),
      }),
      archiveMutation: useMutation<
        TData,
        TError,
        DefaultDeleteMutationVariables
      >({
        mutationFn: (variables: DefaultDeleteMutationVariables) =>
          api.archive<number>(variables),
        ...(props?.archive as any),
      }),
      unarchiveMutation: useMutation<
        TData,
        TError,
        DefaultDeleteMutationVariables
      >({
        mutationFn: (variables: DefaultDeleteMutationVariables) =>
          api.unarchive<number>(variables),
        ...(props?.unarchive as any),
      }),
      unarchivesMutation: useMutation<
        TData,
        TError,
        DefaultDeleteMutationVariables
      >({
        mutationFn: (variables: DefaultDeleteMutationVariables) =>
          api.unarchives<number[]>(variables),
        ...(props?.unarchives as any),
      }),
      restoreMutation: useMutation<
        TData,
        TError,
        DefaultDeleteMutationVariables
      >({
        mutationFn: (variables: DefaultDeleteMutationVariables) =>
          api.restore<number[]>(variables),
        ...(props?.restore as any),
      }),
    }
  }
}
