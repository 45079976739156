import React from 'react'

import { Col, InputNumber, Row, Space } from 'antd'
import { InputNumberProps } from 'antd/lib/input-number'
import useTitleAndError, {
  UseTitleAndErrorProps,
} from 'fields/useTitleAndError/useTitleAndError'
import { FormikContextType, useField } from 'formik'

export type FInputNumberProps = InputNumberProps &
  UseTitleAndErrorProps & {
    name: string
    inline?: boolean
    forceInt?: boolean
    /**
     * Formik Context
     */
    formik?: FormikContextType<any>
  }

function FInputNumberDecimal(props: FInputNumberProps) {
  const [field, meta, helpers] = useField(props as any)

  const [title, error] = useTitleAndError(props)
  const { inline } = props
  if (inline) {
    return (
      <Row align="middle" gutter={10}>
        {title}
        <Col xs={13}>
          <InputNumber
            formatter={(value) => {
              return `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            }}
            onBlur={() => {
              helpers.setTouched(true)
            }}
            onChange={(value) => {
              if (props.forceInt) {
                helpers.setValue(parseInt(value || 0) || 0)
              } else {
                helpers.setValue(value)
              }
            }}
            {...field}
            {...props}
          />
        </Col>
        {meta.touched && error}
      </Row>
    )
  }

  return (
    <React.Fragment>
      {title}
      <Space direction="vertical" style={{ width: '100%' }}>
        <InputNumber
          {...field}
          style={{
            width: '100%',
          }}
          formatter={(value) => {
            const decimalLength = value.split('.')[1]?.length
            if (decimalLength > 3) {
              return `${Number(value).toFixed(3)}`.replace(
                /\B(?=(\d{3})+(?!\d))/g,
                ',',
              )
            }
            return `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
          }}
          onChange={(value) => {
            if (props.forceInt) {
              helpers.setValue(parseInt(value || 0) || 0)
            } else {
              helpers.setValue(value)
            }
          }}
          {...props}
        />
        {meta.touched && error}
      </Space>
    </React.Fragment>
  )
}

export default FInputNumberDecimal
