import type { FormikProps } from 'formik'
import { toLower } from 'lodash'
import { languageService } from 'services'

import useGetAvailableLang from 'data/Language/useGetAvailableLang'
import useGetCurrentLang from 'data/Language/useGetCurrentLang'
import useUpdateTranslatableFieldMutation from 'data/Language/useUpdateTranslatableFieldMutation'

import { DataSubmitProps } from 'components/forms/ModalMultilanguage/ModalMultilanguage'

type TranslateableData = {
  [key: string]: { id: number; lang: string; value: string }[]
}
type Register = {
  data: TranslateableData
  modelId: string | number
  field: string | string[]
}

type Options = {
  enabled: boolean
}
const useMultiLanguage = (model?: string, options?: Partial<Options>) => {
  const update = useUpdateTranslatableFieldMutation()

  const { data: dataAvailableLang } = useGetAvailableLang({
    enabled: options?.enabled ?? true,
  })
  const { data: currentLanguage } = useGetCurrentLang()

  return {
    // Re-export model name
    model,

    /**
     * ir.translation need to register first before change value
     *
     *
     * @param data array of object that hold state for translation
     * @param modelId id from model that use our translation
     * @param field key that used by ir.translation
     */
    register: async ({ data, modelId, field }: Register) => {
      if (!model) {
        throw new Error('Model must be filled!')
      }
      if (!modelId) {
        throw new Error('Model ID must be filled!')
      }

      let dataTranslations = []

      if (Array.isArray(field)) {
        dataTranslations = field.reduce((acc, curr) => {
          acc = [
            ...acc,
            ...(data?.[curr] || []).map((item) => ({
              ...item,
              field: curr,
            })),
          ]
          return acc
        }, [])
      } else {
        dataTranslations = [...(data?.[field] || [])]
      }
      if (dataTranslations) {
        if (Array.isArray(field)) {
          // using for loop instead of promise all to avoid concurency error from BE
          for (const f of field) {
            const translateFields = await languageService.getTranslatableFields(
              {
                id: modelId,
                field: f,
                model,
              },
              dataAvailableLang.map((data) => data.key),
            )
            for (const dataTranslation of dataTranslations) {
              const one = translateFields.result.records.find(
                (item) =>
                  item.lang === dataTranslation?.lang &&
                  dataTranslation.field === f,
              )
              if (one && one.lang !== currentLanguage) {
                await update.mutateAsync({
                  id: Number(one.id),
                  data: {
                    value: dataTranslation.value,
                  },
                })
              }
            }
          }
          return true
        } else {
          const translateFields = await languageService.getTranslatableFields(
            {
              id: modelId,
              field,
              model,
            },
            dataAvailableLang.map((data) => data.key),
          )

          if (translateFields?.result?.records?.length) {
            await Promise.all(
              dataTranslations.map((dataTranslation) => {
                const one = translateFields.result.records.find(
                  (item) => item.lang === dataTranslation?.lang,
                )
                if (one && one.lang !== currentLanguage) {
                  return update.mutateAsync({
                    id: Number(one.id),
                    data: {
                      value: dataTranslation.value,
                    },
                  })
                }
                return true
              }),
            )
          }
        }
      }
    },

    /**
     * Update value from ir.translation
     *
     * @param data array of object that hold state for translation
     * @param field key that used by ir.translation
     */
    update: async ({ data, field }: Pick<Register, 'data' | 'field'>) => {
      if (!model) {
        throw new Error('Model must be filled!')
      }
      let dataTranslations = []
      if (Array.isArray(field)) {
        dataTranslations = field.flatMap((f) => data?.[f]).filter((f) => f)
      } else {
        dataTranslations = [...(data?.[field] || [])]
      }
      if (dataTranslations.length) {
        await Promise.all(
          dataTranslations.map((d) => {
            if (d.lang !== currentLanguage) {
              return update.mutateAsync({
                id: d.id,
                data: {
                  value: d.value,
                },
              })
            }
            return true
          }),
        )
      }
    },

    /**
     * formikOnSubmit
     * helper used on page that use formik to manage state
     *
     * @param formik
     * @param field
     * @returns
     */
    formikOnSubmit:
      (formik: FormikProps<any>, field: string) =>
      (values: DataSubmitProps[]) => {
        const lang = values.find((f) => f.lang === currentLanguage)
        if (lang.value) {
          formik.setFieldValue(field, lang.value)
        }
        formik.setFieldValue(`translateable.${field}`, values)
      },

    batchField: ({ key, name }) => {
      return dataAvailableLang
        ?.map((lang, idx) => ({
          key: `${key}_${toLower(lang.key)}`,
          name: currentLanguage === lang.key ? name : `${name} ${lang.value}`,
          required: currentLanguage === lang.key,
          sortIndex: currentLanguage === lang.key ? 0 : idx + 1,
        }))
        .sort((a, b) => a.sortIndex - b.sortIndex) // Sort current language at first
        .map(({ sortIndex: _, ...fields }) => ({ ...fields }))
    },
  }
}

export default useMultiLanguage
