import React from 'react'

import { Spin } from 'antd'

import cssLoading from './Loading.module.scss'

const Loading = () => {
  return (
    <div data-testid="loading" className={cssLoading.loading}>
      <Spin size="large" />
      <p>Loading...</p>
    </div>
  )
}

export default Loading
