import { notification } from 'antd'

export const MAP_LANGUAGE: Record<string, string> = {
  en: 'en_US',
  ja: 'ja_JP',
}

export type WithRequiredProperty<Type, Key extends keyof Type> = Type & {
  [Property in Key]-?: Type[Property]
}

export const available_BE_models = [
  'warehouse.display.pattern',
  'warehouse.return.instruction',
  'product.product',
  'product.template',
  'product.display.pattern',
  'product.ledger',
  'product.composition',
  'product.composition.mix',
  'product.specification.image',
  'classification.master',
  'first.classification.master',
  'second.classification.master',
  'third.classification.master',
  'fourth.classification.master',
  'fifth.classification.master',
  'account.move',
  'account.move.line',
  'account.move.list',
  'account.tax',
  'account.tax.symbol',
  'account.payment',
  'stock.picking',
  'stock.location',
  'stock.quant',
  'stock.move',
  'stock.move.line',
  'stock.scrap',
  'stock.scrap.slip',
  'sale.order',
  'sale.order.line',
  'exhibition.product.group',
  'exhibition.product.group.line',
  'exhibition.customer.order',
  'exhibition.customer.order.line',
  'exhibition.customer.activity',
  'color.master',
  'color.preset',
  'color.group.pattern',
  'color.group.intensity',
  'color.image',
  'size.master',
  'size.preset',
  'brand.master',
  'customer.brand.master',
  'item.master',
  'jan.code.master',
  'type.master',
  'gender.master',
  'fit.master',
  'front.fit.master',
  'category.master',
  'collar.master',
  'length.master',
  'sleeve.master',
  'composition.master',
  'uom.master',
  'sub.uom.master',
  'store.sales',
  'store.rank.setting',
  'md.product',
  'md.order.pattern.size',
  'order.plan.category',
  'order.pattern.category',
  'return.instruction.products',
  'calendar.master',
  'calendar.season',
  'calendar.season.label',
  'ir.translation',
  'ir.module.module',
  'ir.model.data',
  'res.lang',
  'res.company',
  'res.partner',
  'res.partner.billing.method',
  'res.country',
  'res.country.state',
  'res.currency',
  'res.currency.rate',
  'res.users',
  'res.groups',
  'alert.notification',
  'wrapping.item',
  'change.lock.date',
  'change.lock.date.history',
  'manufacturing.order',
  'manufacturing.order.line',
  'manufacturing.order.line.material',
  'production.control',
  'pattern.preset',
  'pattern.master',
  'customer.product',
  'customer.master',
  'master.measurement',
  'master.measurement.part',
  'master.accessory.position',
  'tag.price',
  'tag.price.fields',
  'organization.master',
  'stock.warehouse',
  'billing.method',
  'billing.to.master',
  'billing.to.master.customer.line',
  'billing.to.master.store.line',
  'billing.to.master.partner.warehouse.line',
  'exclude.billing.to.master',
  'form.transaction',
  'form.operation',
  'form.sale',
  'sales.channel.master',
  'location.master',
  'region.master',
  'virtual.store.master',
  'first.store.group.master',
  'second.store.group.master',
  'third.store.group.master',
  'first.customer.group.master',
  'second.customer.group.master',
  'third.customer.group.master',
  'sumaregi.setting',
  'sales.linkage.history',
  'sales.billing',
  'sales.billing.lines',
  'sales.billing.invoice',
  'inventory.linkage.summary',
  'shipment.company',
  'account.adjustment',
  'account.fiscal.year',
  'calendar.holiday',
  'mail.message.content',
  'mail.message',
  'md.display.pattern',
  'product.specification.documents',
  'product.specification.sewing',
  'product.specification.fabric',
  'product.specification.packing',
  'product.specification.inspection',
  'product.specification.pattern.store',
  'product.specification.secondary.process',
  'queue.job',
  'sheet.instruction.material',
  'pos.device',
  'batch.registration',
  'batch.sequence',
  'quotation.master',
  'quotation.material.line',
  'order.master',
  'order.material.line',
  'order.product.line',
  'incoterm.master',
  'inventory.allocation',
  'inventory.allocation.setting',
  'inventory.allocation.status',
  'inventory.allocation.priority_setting',
  'download.background',
  'membership.master',
  'ec.reserve.order',
  'ec.reserve.order.line',
  'picking.document',
  'payment.statement',
  'trade.terms',
  'ec.order.receive',
  'receipt.order',
  'receipt.order.product.line',
  'ec.coupon',
  'ec.coupon.history',
  'coupon.coupon',
  'company.label.master',
  'country.label.master',
  'phone.number.label.master',
  'attention.term.master',
  'supplementary.term.master',
  'multi.process',
  'multi.process.line',
  'inventory.adjustment',
  'inventory.adjustment.line',
  'inventory.evaluation.change',
  'inventory.evaluation.change.line',
  'stock.taking',
  'stock.taking.lines',
  'stock.taking.date.setting',
  'stock.taking.group',
  'stock.production.lot',
  'warehouse.stock.shelf',
  'logistic.linkage.sequence',

  'trading.expense',
  'trading.expense.lines',
  'custom.clearance',
  'mrp.bom.assembly',
  'material.replenishment.request',
  'warehouse.receive.plan.responsible',
  'warehouse.safety.inventory.setting',

  'md.plan.setting',

  // deprecated
  'store.master',
] as const
type available_BE_Models_list = typeof available_BE_models
export type Available_BE_Models = available_BE_Models_list[number]

export const notificationResponseInterceptorErrorKey =
  'notification-error-api-response-interceptor'
export const notificationApiWrapperErrorKey = 'notification-error-api-wrapper'

export enum ORM_CODE {
  CREATE = 0,
  UPDATE = 1,
  DELETE = 2,
  UNLINK = 3,
  LINK = 4,
  CLEAR = 5,
  SET = 6,
}

export const getErrorMessage = (error: any) => {
  return (
    error?.response?.data?.errors ||
    error?.response?.data?.error?.message ||
    error?.response?.data?.error?.data?.message ||
    error?.response?.data?.message ||
    error?.response?.data?.error ||
    error?.message
  )
}

export type DefaultFilePostErrorMessage = {
  code: string
  column: string[]
  message: string
  row: number
}[]

export const getErrorCode = (error: any) => {
  return (
    error?.response?.data?.code ||
    error?.response?.data?.error?.code ||
    error?.response?.data?.error?.data?.code ||
    error?.code
  )
}

export class ApiErrorResponse extends Error {
  rawError: any
  errorCode: any
  constructor(rawError: any, options?: ErrorOptions) {
    super(getErrorMessage(rawError), options)
    this.message = getErrorMessage(rawError)
    this.errorCode = getErrorCode(rawError)
    this.rawError = rawError
  }

  get messageIsString() {
    return typeof this.message === 'string'
  }

  get stringMessage() {
    const err = getErrorMessage(this.message)
    return typeof err === 'string' ? err : JSON.stringify(err)
  }

  showErrorNotification({
    forceString = false,
  }: { forceString?: boolean } = {}) {
    if (forceString) {
      notification.error({
        key: 'notification-error-api-wrapper',
        message: this.message ? this.stringMessage : 'Internal server error',
      })
    } else if (typeof this.message === 'string' || !this.message) {
      notification.error({
        key: 'notification-error-api-wrapper',
        message: this.stringMessage || 'Internal server error',
      })
    }
  }

  get filePostErrorMessages(): DefaultFilePostErrorMessage {
    return this.message as any
  }
}
