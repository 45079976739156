import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'

import Link from 'next/link'
import { useRouter } from 'next/router'

import { BellOutlined } from '@ant-design/icons'
import { Avatar, Badge, Col, Dropdown, Image, List, Menu, Space } from 'antd'
import DevEnvironmentInfo from 'layouts/containers/Public/partials/DevEnvironmentInfo'
import moment from 'moment'
import ApiCall from 'services/ApiCall'

import useMenu from 'data/useMenu'

import BaseHeader from 'components/BaseHeader/BaseHeader'
import Text from 'components/Typography/Text'
import limitString from 'helpers/limitString'
import titleHeader from 'helpers/titleHeader'
import PUBLIC_PAGES from 'utils/constants/page.constant'

import styles from './Header.module.scss'

const ForwardImage = React.forwardRef((props, _) => (
  <Image
    src="/images/LogoLDX-Blue-01.png"
    height={80}
    width={90}
    preview={false}
    {...props}
  />
))

function Header() {
  const { t, ready } = useMenu()
  const { t: tCommon, i18n } = useTranslation('common')
  const router = useRouter()
  const menus = ready ? (t('menus', { returnObjects: true }) as any) : []
  const [title, setTitle] = useState('')
  const [name, setName] = useState('')
  const [notificationData, setNotificationData] = useState<any>([])
  const [priorityStatus, setPriorityStatus] = useState(false)
  const [visibleNotification, setVisibleNotification] = useState(false)
  const [unreadNotification, setUnreadNotification] = useState(false)
  const [visibleProfile, setVisibleProfile] = useState(false)
  const notificationRef = useRef(false)

  //? EM-60 - Disable auto logout temporarily
  // useUserIdleActivityTimer({
  //   onIdleApply() {
  //     localStorage.removeItem('token-ldx')
  //     localStorage.removeItem('key-pin')
  //     localStorage.removeItem('refresh-token')
  //     window.location.replace(
  //       `/?prevPage=${window.location.pathname}${window.location.search}&error=logout_due_inactivity`,
  //     )
  //   },
  // })

  useEffect(() => {
    if (!i18n?.language?.includes('en')) {
      moment.locale('ja')
    } else {
      moment.locale('en')
    }
  }, [i18n.language])

  useEffect(() => {
    setTitle(titleHeader(t, router.pathname, menus))
    const localName = localStorage.getItem('name')
    setName(localName)
  }, [menus, router.pathname])

  function handleClick(e) {
    const { key } = e
    if (key === '2') {
      localStorage.removeItem('token-ldx')
      localStorage.removeItem('key-pin')
      localStorage.removeItem('refresh-token')
      window.location.replace('/')
    }

    if (key === '3') {
      router.push('/profile/security')
    }
  }

  const markAllRead = useMutation(() => ApiCall.Notification.markAllRead())

  const getNotification = async (previous = []) => {
    try {
      const res = await ApiCall.Notification.getAutoNotification({
        use_pagination: false,
      })
      if (res.data.error) {
        throw new Error(res.data.error?.data?.message)
      } else {
        if (JSON.stringify(previous) !== JSON.stringify(res?.data?.result)) {
          if (!notificationRef.current) {
            const unread_count = res?.data?.result?.unread_count || 0
            const records = res?.data?.result?.records || []
            const priority = res?.data?.result?.high_priority_notification || []
            if (priority.length) {
              setPriorityStatus(true)
            }
            if (unread_count) {
              setUnreadNotification(true)
            }
            setNotificationData({
              unread: unread_count || 0,
              records:
                [
                  ...priority.map((data) => ({ ...data, priority: true })),
                  ...records.map((data) => ({ ...data, priority: false })),
                ] || [],
            })
          }
        }
        setTimeout(() => {
          getNotification(res?.data?.result)
          notificationRef.current = false
        }, 60000)
      }
    } catch (error) {
      console.error(error)
    }
  }

  // useEffect(() => {
  //   queryClient.invalidateQueries('/countries')
  // }, [language])

  useEffect(() => {
    if (ready && !PUBLIC_PAGES.includes(router.pathname)) {
      getNotification()
    }
  }, [ready])

  useEffect(() => {
    if (!visibleNotification && unreadNotification) {
      markAllRead.mutateAsync()
    }
  }, [visibleNotification])

  const profileMenu = (
    <Menu onClick={handleClick} className="mt-5 mr-3 w-40">
      {/* <Menu.Item key="1">Profile</Menu.Item> */}
      <Menu.Item key="3">{t('common:profile')}</Menu.Item>
      <Menu.Item key="2">{t('common:logout')}</Menu.Item>
    </Menu>
  )

  const notificationMenu = (
    <Menu className={styles['notification-container']}>
      <div className={styles['notification-header']}>
        {' '}
        {tCommon('notification')}{' '}
      </div>
      <div className={styles['notification-body']}>
        <List
          itemLayout="horizontal"
          dataSource={notificationData?.records?.map((notification) => ({
            title: notification?.subject,
            author: notification?.author,
            description: notification?.message || '-',
            read: notification?.is_read,
            priority: notification?.priority,
            screen_url: notification?.screen_url,
            time: notification?.created_at,
          }))}
          renderItem={(item: any, i) => (
            <List.Item
              key={i}
              className={
                item.read && !item.priority
                  ? styles['notification-body-list-item']
                  : styles['notification-body-list-item-unread']
              }
              onClick={() => [
                item?.screen_url === router.pathname
                  ? window.location.reload()
                  : router.replace(item?.screen_url),
              ]}
            >
              <div className={styles['notification-body-list-item-key']}>
                <div className={styles['notification-body-title-container']}>
                  <div className={styles['notification-body']}>
                    <div className={styles['notification-body-title']}>
                      {limitString(
                        `${item?.author || ''} - ${titleHeader(
                          t,
                          item?.screen_url,
                          menus,
                        )}`,
                        25,
                      )}
                    </div>
                    <div className={styles['notification-body-time']}>
                      {moment(moment.utc(item.time).local().format()).fromNow()}
                    </div>
                  </div>
                  <div className={styles['notification-body-time']}>
                    {item?.created || ''}
                  </div>
                </div>
                <div className={styles['notification-body-message']}>
                  {limitString(item?.description, 50)}
                </div>
              </div>
            </List.Item>
          )}
        ></List>
      </div>
      <Link
        passHref
        href="/notification"
        onClick={() => setVisibleNotification(false)}
      >
        <div className={styles['notification-footer']}>
          {' '}
          {tCommon('show_more')}{' '}
        </div>
      </Link>
    </Menu>
  )

  return (
    <BaseHeader>
      <Col flex={'auto'} className={styles['logo-header']}>
        <Link passHref href="/home">
          <ForwardImage />
        </Link>
        <DevEnvironmentInfo />
      </Col>
      <Col
        flex={'auto'}
        className={styles['title-header']}
        data-testid="page-title__header"
      >
        <Text data-cy="headerPageTitle">{title}</Text>
      </Col>
      <Col flex={'auto'} className={styles['avatar-header']}>
        <Space size="large">
          {!PUBLIC_PAGES.includes(router.pathname) && (
            <Dropdown
              overlay={notificationMenu}
              trigger={['click']}
              placement={'bottom' as any}
              className="flex cursor-pointer items-center"
              visible={visibleNotification}
              onVisibleChange={() =>
                setVisibleNotification(!visibleNotification)
              }
            >
              <Badge
                count={
                  notificationData?.unread >= 9
                    ? `9+`
                    : notificationData?.unread !== 0
                    ? notificationData?.unread
                    : priorityStatus
                    ? '!'
                    : 0
                }
              >
                <BellOutlined
                  style={{ fontSize: '22px', color: 'rgb(4, 34, 136)' }}
                />
              </Badge>
            </Dropdown>
          )}

          {!PUBLIC_PAGES.includes(router.pathname) && (
            <Dropdown
              overlay={profileMenu}
              trigger={['click']}
              placement={'bottom' as any}
              visible={visibleProfile}
              onVisibleChange={() => setVisibleProfile(!visibleProfile)}
            >
              <a>
                <Avatar size={'large'} style={{ backgroundColor: '#2D70E3' }}>
                  <Text bold>{name?.slice(0, 2)}</Text>
                </Avatar>
              </a>
            </Dropdown>
          )}
        </Space>
      </Col>
    </BaseHeader>
  )
}

export default Header
