import { generateApiRoutes } from 'utils/helpers'

const API_ROUTE = {
  PRODUCT_DISPLAY_PATTERN: generateApiRoutes('product.display.pattern'),
  STOCK_PICKING: generateApiRoutes('stock.picking', ['create_purchase_slip']),
  WAREHOUSE_DISPLAY_PATTERN: generateApiRoutes('warehouse.display.pattern'),
  PRODUCT_SEARCH: generateApiRoutes('product.template'),

  ACCOUNT_MOVE: generateApiRoutes('account.move', [
    'create_adjustment',
    'create_adjustment_multi',
    'unlink_adjustment',
  ]),
  ACCOUNT_MOVE_LINE: generateApiRoutes('account.move.line'),
  ACCOUNT_MOVE_LIST: generateApiRoutes('account.move.list', [
    'register_invoice_payment',
    'cancel_invoice_payment',
    'search_read_by_group',
    'search_read_by_linked',
    'search_read_payment_schedule',
    'action_mark_suspension',
    'download_excel',
    'generate_billing',
    'generate_billing_dict',
    'download_excel_payment_schedule',
    'export_excel',
    'batch_prepare_billing',
    'create_payment_statement',
    'batch_prepare_payment_statement',
    'search_read_payment_schedule_line',
    'download_excel_linked',
  ]),
  WAREHOUSE_RETURN_INSTRUCTION: generateApiRoutes(
    'warehouse.return.instruction',
    [
      'action_mark_return_confirmed',
      'fetch_slip_no_data',
      'fetch_slip_no_product_line_data',
    ],
  ),
  RETURN_INSTRUCTION_PRODUCT: generateApiRoutes('return.instruction.products'),
  SALE_ORDER: generateApiRoutes('sale.order'),
  CALENDAR_MASTER: generateApiRoutes('calendar.master', [
    'generate_calendar',
    'write_dates',
  ]),
  STOCK_QUANT: generateApiRoutes('stock.quant', [
    'read_inventory_list_v2',
    'download_inventory_list_all',
  ]),
  PRODUCTION_CONTROL: generateApiRoutes('production.control', [
    'tag_export_excel',
    'tag_export_csv',
  ]),
  EXHIBITION_PRODUCT_GROUP: generateApiRoutes('exhibition.product.group', [
    'get_distinct_product_template',
  ]),
  PRODUCT_LEDGER: generateApiRoutes('product.ledger', [
    'product_information_customer_order',
    'product_information_order',
    'product_information_stock_picking',
    'product_information_sales',
    'product_information_return',
    'product_information_warehouse',
  ]),
  EXHIBITION_ORDER: generateApiRoutes('exhibition.customer.order', [
    'register_customer_order',
  ]),
  PRODUCT_PRODUCT: generateApiRoutes('product.product', [
    'search_read_with_inventory',
    'write_multiple',
    'search_product_by_material',
    'search_material_by_product',
    'get_dropdown_data',
    'product_data_detail',
  ]),
  MANUFACTURING_ORDER: generateApiRoutes('manufacturing.order', [
    'action_start_manufacturing_order',
    'action_confirm_manufacturing_order',
    'create_service_ap',
    'action_cancel',
  ]),
  MD_PRODUCT: generateApiRoutes('md.product', [
    'data_read_product',
    'data_read',
  ]),
  MD_ORDER_PATTERN_SIZE: generateApiRoutes('md.order.pattern.size', [
    'difference_check_md_plan_order',
  ]),
  MD_ORDER_PLAN_CATEGORY: generateApiRoutes('order.plan.category', [
    'data_read_group',
    'bulk_write_group',
    'data_read',
    'bulk_write_line',
    'data_read_ratio',
    'bulk_write_ratio',
    'data_read_product_rank',
    'bulk_write_product_rank',
  ]),
  MD_ORDER_PATTERN_CATEGORY: generateApiRoutes('order.pattern.category', [
    'setting_order_qty_ratio',
    'setting_product_rank',
    'preview_last_md_actual',
    'bulk_write',
    'bulk_write_lines',
    'get_datas',
    'get_group_datas',
  ]),
  MD_STORE_RANK_SETTING: generateApiRoutes('store.rank.setting', ['get_datas']),
  WRAPPING_ITEM: generateApiRoutes('wrapping.item'),
  USER: generateApiRoutes('res.users', [
    'prepare_2FA',
    'activate_2FA',
    'deactivate_2FA',

    'action_totp_enable_wizard',
    'action_totp_disable',
  ]),
  USER_IDENTITY_CHECK: generateApiRoutes('res.users.identitycheck', [
    'run_check',
  ]),
  AUTH_TOTP: generateApiRoutes('auth_totp.wizard', ['enable']),
  CHANGE_LOCK_DATE: generateApiRoutes('change.lock.date', [
    'action_close_period',
    'action_open_period',
    'is_date_period_close',
  ]),
  STORE_SALES: generateApiRoutes('store.sales', [
    'calculate_purchase_analysis',
    'download_purchase_analysis',
  ]),
  TAX_EXPORT_SETTING: generateApiRoutes('tag.price', [
    'create_tag',
    'write_tag',
    'download_tag_csv',
    'download_tag_xls',
  ]),
  VIRTUAL_STORE: generateApiRoutes('virtual.store.master'),
}

export default API_ROUTE
