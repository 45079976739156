import { useQuery, UseQueryOptions } from 'react-query'

import { useRouter } from 'next/router'

import { AxiosError } from 'axios'
import { BASE_API_URL } from 'constant'
import ApiCall from 'services/ApiCall'

import { useCurrentUser } from 'data/useUsers'

import PUBLIC_PAGES from 'utils/constants/page.constant'

export interface UseUserACL {
  acl: {
    menu: string
    access_level: 'read' | 'write' | 'approve'
    self_data_only: boolean
  }[]
}

type TQueryFnData = UseUserACL
type TError = AxiosError
type IParams = {
  queryKey?: string
  options?: UseQueryOptions<TQueryFnData, TError>
}

/**
 * List of user access url pages
 *
 * the query will disabled if user access `PUBLIC_PAGES`
 */
function useUserACL(params: IParams = {}) {
  const { queryKey, options } = params
  const currentUserId = useCurrentUser()
  const router = useRouter()
  const qKey = [`/get-user-acl`, queryKey, `user-${currentUserId}`]

  const query = useQuery<TQueryFnData, TError>(
    qKey,
    () =>
      ApiCall.api
        .get(`${BASE_API_URL}/user/${currentUserId}/acl/full`)
        .then((res) => {
          return res.data
        }),
    {
      ...options,
      enabled: currentUserId > 0 && !PUBLIC_PAGES.includes(router.pathname),
    },
  )

  return {
    ...query,
    data: query.data?.acl,
    queryKey: qKey,
    isReady:
      router.isReady &&
      !query.isFetching &&
      query.isSuccess &&
      currentUserId > 0,
  }
}

export default useUserACL
