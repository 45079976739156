import { QueryFunction, QueryObserverOptions, useQuery } from 'react-query'

import { omit } from 'lodash'

export type QueryOptions<T> = QueryObserverOptions<
  ReactQueryResponse<T>['result']
>

export type SearchQueryKey = [string, 'list', SearchParams]
const useSearchQuery = <T = any>(
  queryFn: QueryFunction<ReactQueryResponse<T>['result'], SearchQueryKey>,
  params?: SearchParams,
  options?: QueryOptions<T>,
) => {
  const queryKey = [
    queryFn.name,
    params?.queryKey,
    omit(params, ['queryKey']),
    ...(Array.isArray(options?.queryKey)
      ? options?.queryKey
      : [options?.queryKey]),
  ]
  const { data, ...query } = useQuery(queryKey, queryFn, {
    keepPreviousData: true,
    ...options,
  })

  return {
    ...query,
    queryKey,
    data: data?.records ?? [],
    length: data?.length ?? 0,
  }
}

export default useSearchQuery
