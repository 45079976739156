import React, { useMemo } from 'react'

import ErrorView from 'fields/ErrorView/ErrorView'

import Text from 'components/Typography/Text'

export interface UseTitleAndErrorProps {
  name?: any
  // i edited this because i need to send an object props
  title?: string | object
  // -------
  requiredMark?: boolean
}

function useTitleAndError(options: UseTitleAndErrorProps) {
  const { title, name, requiredMark = false } = options
  const nodeTitle = useMemo(() => {
    return requiredMark ? (
      <React.Fragment>
        <Text color="black">{title}</Text>
        <Text color="red" style={{ marginLeft: '4px' }} size={16}>
          *
        </Text>
      </React.Fragment>
    ) : (
      <Text color="black">{title}</Text>
    )
  }, [title])

  const nodeError = name && <ErrorView name={name} />

  return [nodeTitle, nodeError]
}

export default useTitleAndError
