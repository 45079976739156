const getTextAlignment = (format: string) => {
  if (
    format === 'currency' ||
    format === 'date' ||
    format === 'percent-comma' ||
    format === 'percentage' ||
    format === 'datetime'
  ) {
    return 'right'
  }

  return 'left'
}

export default getTextAlignment
