import React from 'react'

import type { DatePickerProps } from 'antd'
import { DatePicker as AntDatePicker, Form } from 'antd'

import { FORMAT_DATE } from 'utils/constants'
import useAccountClosingStore from 'views/InventoryControl/ClosingProcessSetting/stores/accountClosingStore'

type Props = DatePickerProps &
  TooltipInputProps & {
    lockDate?: boolean
    ref?: any
  }
const DatePicker: React.FC<Props> = ({
  errorMessage: _e,
  showErrorMessage: _m,
  className,
  disabled,
  lockDate = true,
  ...props
}): JSX.Element => {
  // const isClosed = useAccountClosingStore((state) => state.isClosed)
  const closedDate = useAccountClosingStore((state) => state.closedDate)
  const form = Form.useFormInstance()
  const formName = Form.useWatch('form_name', form)

  const isFieldDisabled = disabled
  // if (isClosed && lockDate) {
  //   isFieldDisabled = true // set field always disabled
  // }
  return (
    <AntDatePicker
      allowClear
      format={FORMAT_DATE}
      className={`w-full ${className}`}
      disabled={isFieldDisabled}
      disabledDate={(date) =>
        date.format(FORMAT_DATE) <= closedDate?.format(FORMAT_DATE) &&
        lockDate &&
        formName !== 'filterForm'
      }
      {...props}
    />
  )
}

export default DatePicker
