import React from 'react'

import { DatePicker, Form } from 'antd'
import { RangePickerProps } from 'antd/lib/date-picker'

import { FORMAT_DATE } from 'utils/constants'
import useAccountClosingStore from 'views/InventoryControl/ClosingProcessSetting/stores/accountClosingStore'

type Props = RangePickerProps & {
  lockDate?: boolean
}
const RangePicker: React.FC<Props> = ({
  lockDate = true,
  disabled,
  ...props
}): JSX.Element => {
  const closedDate = useAccountClosingStore((state) => state.closedDate)
  const form = Form.useFormInstance()
  const formName = Form.useWatch('form_name', form)

  const isFieldDisabled = disabled
  // if (isClosed && lockDate) {
  //   isFieldDisabled = true // set field always disabled
  // }
  return (
    <DatePicker.RangePicker
      className="w-full"
      format={FORMAT_DATE}
      disabled={isFieldDisabled}
      disabledDate={(date) =>
        date.format(FORMAT_DATE) <= closedDate?.format(FORMAT_DATE) &&
        lockDate &&
        formName !== 'filterForm'
      }
      {...props}
    />
  )
}

export default RangePicker
