import React from 'react'

import { sumBy } from 'lodash'

export interface FilterFields {
  width: number
  component: JSX.Element
  hidden?: boolean
}

const generateExpandFilter = (
  expand: boolean,
  filterFields: FilterFields[],
) => {
  return expand
    ? filterFields
        .filter((item) => !item.hidden)
        .map((item, idx) => React.cloneElement(item.component, { key: idx }))
    : filterFields
        .filter((item) => !item.hidden)
        .reduce((acc, curr, _, arr) => {
          const newAcc = [...acc, curr]
          if (sumBy(newAcc, 'width') > 3) {
            // to end reduce immediately
            arr.splice(1)
            return acc
          }

          return newAcc
        }, [])
        .map((item, idx) => React.cloneElement(item.component, { key: idx }))
}

export default generateExpandFilter
