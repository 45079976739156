import { Decimal } from 'decimal.js'
import { isNil, isNull, isUndefined, max } from 'lodash'

import { parseNumber } from 'utils'

const FORMAT_DATE = 'YYYY/MM/DD'
const FORMAT_DATETIME = 'YYYY/MM/DD HH:mm:ss'
const FORMAT_DATETIME_WITHOUT_SECOND = 'YYYY/MM/DD HH:mm'
const FORMAT_BACKEND_DATE = 'YYYY-MM-DD'
const FORMAT_BACKEND_DATETIME = 'YYYY-MM-DD HH:mm:ss'

import moment from 'moment'

interface NumberFormatOptions extends Intl.NumberFormatOptions {
  roundingMethod?: 'round_up' | 'round_down' | 'rounding'
}

const dateCore = moment

export type DateType = moment.Moment
export const getLocalTimeZoneName = () =>
  Intl.DateTimeFormat().resolvedOptions().timeZone

function formatRoundingMethod({
  amount,
  method,
  decimalPlace,
}: {
  amount: number
  method: NumberFormatOptions['roundingMethod']
  decimalPlace?: number
}) {
  const fixNum = amount
  const decimalPlacePow = new Decimal(10).pow(decimalPlace || 0)
  // const amountPow = Math.abs(fixNum * decimalPlacePow)
  // use this instead of above to avoid floating point error such as 9.7 * 100 = 969.9999999999999
  const amountPow = new Decimal(fixNum).mul(decimalPlacePow).abs().toNumber()

  const negativeSign = fixNum < 0 ? -1 : 1
  const mapMethod = {
    round_up: new Decimal(Math.ceil(amountPow))
      .mul(negativeSign)
      .div(decimalPlacePow)
      .toNumber(),
    round_down: new Decimal(Math.floor(amountPow))
      .mul(negativeSign)
      .div(decimalPlacePow)
      .toNumber(),
    rounding: new Decimal(Math.round(amountPow))
      .mul(negativeSign)
      .div(decimalPlacePow)
      .toNumber(),
  }

  return mapMethod[method || 'rounding']
}

const adjustDate = (date: DateType, type?: 'startOfDay' | 'endOfDay') => {
  if (type === 'startOfDay') return date.startOf('day')
  if (type === 'endOfDay') return date.endOf('day')
  return date
}

export const DateConfig = {
  date: dateCore,
  isDateInstance: (value: any) => {
    return dateCore(value).isValid()
  },
  utcToLocalDate: (val: any) => {
    if (!val) return undefined
    const m = dateCore.utc(val)
    return m.isValid() ? dateCore(m.toISOString()).local() : undefined
  },
  getLocalTimeZoneName: () => Intl.DateTimeFormat().resolvedOptions().timeZone,
  /**
   *
   * @param val support utc detection (if val is string and it contain letter T)
   * @returns
   */
  toLocalDate(val: any) {
    if (!val) return undefined
    let result = dateCore(val)
    if (typeof val === 'string' && val.includes('T')) {
      result = dateCore(val).local()
    }
    return result.isValid() ? result : undefined
  },
  to_FE_DateString: (
    val: any,
    options?: { convertToUtc?: boolean; utcToLocal?: boolean },
  ) => {
    if (!val) return ''
    let m = dateCore(val)
    if (!m.isValid()) return ''
    if (options?.convertToUtc) {
      m = m.utc()
    } else if (options?.utcToLocal) {
      m = dateCore.utc(val).local()
    }
    return m.format(FORMAT_DATE)
  },
  to_BE_DateString: (
    val: any,
    options?: {
      defaultFallbackValue?: any
      withTime?: boolean
      toUtc?: boolean
      adjust?: 'startOfDay' | 'endOfDay'
    },
  ) => {
    if (!val) return options?.defaultFallbackValue || ''
    const date = dateCore(val)
    if (!date.isValid()) return options?.defaultFallbackValue || ''
    const m = options?.toUtc
      ? adjustDate(date, options?.adjust).utc()
      : adjustDate(date, options?.adjust)
    return m.isValid()
      ? m.format(
          options?.withTime ? FORMAT_BACKEND_DATETIME : FORMAT_BACKEND_DATE,
        )
      : options?.defaultFallbackValue || ''
  },
  sortDate: (dates: DateType[]) => {
    return dates.sort((a, b) => a.unix() - b.unix())
  },
  rangeToDomain: (
    key: string,
    values: [DateType, DateType],
    options?: { toUtc?: boolean; withTime?: boolean },
  ) => {
    return [
      [
        key,
        '>=',
        DateConfig.to_BE_DateString(values[0], {
          adjust: 'startOfDay',
          ...(options || {}),
        }),
      ],
      [
        key,
        '<=',
        DateConfig.to_BE_DateString(values[1], {
          adjust: 'endOfDay',
          ...(options || {}),
        }),
      ],
    ]
  },
}

/**
 * Parse a localized number to a float.
 * @param {string} stringNumber - the localized number
 * @param {string} locale - [optional] the locale that the number is represented in. Omit this parameter to use the current locale.
 */
function parseLocaleNumber(
  stringNumber: string,
  locale: string | string[],
  options?: NumberFormatOptions,
) {
  const thousandSeparator = Intl.NumberFormat(locale)
    .format(11111)
    .replace(/\d+/g, '')
  const decimalSeparator = Intl.NumberFormat(locale)
    .format(1.1)
    .replace(/\d+/g, '')

  const result = parseFloat(
    stringNumber
      .replace(new RegExp('\\' + thousandSeparator, 'g'), '')
      .replace(new RegExp('\\' + decimalSeparator), '.'),
  )
  if (!options?.roundingMethod) return result
  return formatRoundingMethod({
    amount: result,
    method: options?.roundingMethod,
    decimalPlace: options?.maximumFractionDigits,
  })
}

const formatNumber = (val: any, options?: NumberFormatOptions) => {
  if (!Number(val)) return typeof val === 'number' ? '0' : null
  const _val = options?.roundingMethod
    ? formatRoundingMethod({
        amount: Number(val),
        method: options.roundingMethod,
        decimalPlace: options.maximumFractionDigits,
      })
    : Number(val)
  const result = new Intl.NumberFormat(['ja-JP', 'en-US'], {
    ...options,
    maximumFractionDigits: max([
      !isNaN(options?.maximumFractionDigits)
        ? options?.maximumFractionDigits
        : 2,
      options?.minimumFractionDigits,
    ]),
  }).format(_val)
  return Number(result) === 0 ? '0' : result
}
formatNumber.currency = (val: any, options?: NumberFormatOptions) => {
  return formatNumber(val, { style: 'currency', currency: 'JPY', ...options })
}

function inputNumberFn(val: any) {
  if (Number(val) === 0) return '0'
  return formatNumber(val) || ''
}
inputNumberFn.fallbackValue = (val?: any) => {
  return (...args: Parameters<typeof inputNumberFn>) => {
    if (isNull(args[0])) return val
    if (isNaN(Number(args[0]))) return val
    return inputNumberFn(...args)
  }
}
inputNumberFn.WithOptions = (
  options?: NumberFormatOptions & { fallbackValue?: string },
) => {
  return (val: any) => {
    if (isNaN(Number(val)) || Number(val) === 0)
      return options?.fallbackValue ?? '0'
    return formatNumber(val, options) as string
  }
}
formatNumber.inputFn = inputNumberFn
formatNumber.parseNumber = function parseNumber(
  val: string,
  options?: NumberFormatOptions,
) {
  return parseLocaleNumber(val, ['ja-JP', 'en-US'], options)
}

type FormatDateOptions = {
  utcToLocal?: boolean
  convertOutputToUtc?: boolean
}

const formatDate = (
  val: any,
  time?: boolean,
  format?: string,
  options?: FormatDateOptions,
) => {
  if (isNull(val) || isUndefined(val)) return null
  if (!dateCore(val).isValid() || !val) return null
  const _d = options?.utcToLocal
    ? DateConfig.utcToLocalDate(val)!
    : dateCore(val)!
  const _date = options?.convertOutputToUtc ? _d.utc() : _d
  const stringFormat = format ?? (time ? FORMAT_DATETIME : FORMAT_DATE)
  return dateCore(time ? _date.toDate() : _date)?.format(stringFormat)
}

function formatDateDisplayText(val: any, options?: Intl.DateTimeFormatOptions) {
  if (!val || !dateCore(val).isValid()) return null
  val = dateCore(val).toDate()
  return new Intl.DateTimeFormat(['ja-JP', 'en-US'], {
    dateStyle: 'long',
    timeZone: getLocalTimeZoneName(),
    ...options,
  }).format(val)
}

formatDate.withTime = (
  val: any,
  format?: string,
  options?: FormatDateOptions,
) => {
  return formatDate(val, true, format, options)
}
formatDate.DisplayText = formatDateDisplayText
formatDate.DisplayTextWithTime = (
  val: any,
  options?: Intl.DateTimeFormatOptions,
) => {
  return formatDateDisplayText(val, { timeStyle: 'short', ...options })
}

const stringFormat = (val: any) => (isNil(val) ? '' : `${val}`)

export const availableDisplayDataFormater = {
  string: stringFormat,
  text: stringFormat,
  number: formatNumber,
  currency: formatNumber,
  date: formatDate,
  datetime: formatDate.withTime,
  datetime_essential: (val: any, options?: FormatDateOptions) =>
    displayFormater.date.withTime(val, FORMAT_DATETIME_WITHOUT_SECOND, options),
  percentage: (
    val: any,
    { fallbackValue = '0' }: { fallbackValue?: any } = {},
  ) => {
    if (isNaN(Number(val)) || !val) return fallbackValue
    return displayFormater.number(val) as any
  },
  ratio_to_percentage: (
    val: any,
    { fallbackValue = '0' }: { fallbackValue?: any } = {},
  ) => {
    if (isNaN(Number(val)) || !val) return fallbackValue
    return displayFormater.number(val * 100) as any
  },
  'percent-comma': formatNumber,
}
export type AvailableDisplayDataFormaterKeys =
  keyof typeof availableDisplayDataFormater

export const displayFormater = {
  number: availableDisplayDataFormater.number,
  date: availableDisplayDataFormater.date,
}

export function decimalAdjustment(val: number) {
  try {
    return new Decimal(parseNumber(val))
  } catch {
    return new Decimal(0)
  }
}

decimalAdjustment.sum = function sumWithDecimalAdjustment(...args: number[]) {
  if (args?.length === 0) return 0
  // there's case that array element is undefined, null, etc, so parse it first
  return Decimal.sum(...args.map((e) => parseNumber(e))).toNumber()
}
