import { useEffect, useMemo, useState } from 'react'

import Link from 'next/link'
import { NextRouter, withRouter } from 'next/router'

import { Menu } from 'antd'

import useMenu from 'data/useMenu'
import useUserACL from 'data/useUserACL'
import { useCurrentUser } from 'data/useUsers'

import cssMenu from 'layouts/containers/Public/MenuBar/MenuBar.module.scss'

// const { SubMenu } = Menu

/**
 * DO NOT REMOVE THIS FUNCTION, THIS FUNCTION IS USEFULL TO FIX
 * A DUPLICAT MENU ID/KEY
 */
// function getMenuIds(menuList = [], savedIds = []) {
//   menuList.forEach((s) => {
//     savedIds.push(s.id)

//     if (s.submenus) {
//       // eslint-disable-next-line unused-imports/no-unused-vars
//       getMenuIds(s.submenus, savedIds)
//     }
//   })

//   return savedIds
// }

/**
 * DO NOT REMOVE THIS FUNCTION, THIS FUNCTION IS USEFULL TO FIX
 * A DUPLICAT MENU ID/KEY
 *
 * @link https://stackoverflow.com/questions/840781/get-all-non-unique-values-i-e-duplicate-more-than-one-occurrence-in-an-array
 */
// const findDuplicates = (arr) => {
//   // You can define the comparing function here.
//   const sortedArr = arr.slice().sort()
//   // JS by default uses a crappy string compare.
//   // (we use slice to clone the array so the
//   // original array won't be modified)
//   const results = []

//   for (let i = 0; i < sortedArr.length - 1; i += 1) {
//     if (sortedArr[i + 1] === sortedArr[i]) {
//       results.push(sortedArr[i])
//     }
//   }

//   return results
// }

const hiddenEnv = (env = []) => {
  let hidden = true
  const BE_URL = process?.env?.BE_URL
  env.map((e) => {
    if (BE_URL?.includes(e)) {
      hidden = false
    }
  })

  return hidden
}

const createMenus = (menu, currentUser) => {
  // Hide menu when the admin flag is true but the user is not admin
  if (
    menu.hide ||
    (menu.admin && currentUser !== 2) ||
    (menu.env && hiddenEnv(menu.env))
  ) {
    return null
  }

  if (menu.submenus) {
    return {
      label: menu.name,
      key: `submenus_${menu.id}`,
      children: menu.submenus.map((menu) => createMenus(menu, currentUser)),
    }
  }

  return {
    label: (
      <Link href={menu?.url}>
        <a>{menu.name}</a>
      </Link>
    ),
    key: menu?.url ?? menu.id,
  }
}

function MenuBar({ router }: { router: NextRouter }) {
  const { t, i18n, ready } = useMenu()
  const menus = t('menus', { returnObjects: true }) as any

  const [filteredMenu, setFilteredMenu] = useState([])
  const { data: userAcl } = useUserACL()
  const currentUser = useCurrentUser()

  /**
   * DO NOT REMOVE THIS FUNCTION, THIS FUNCTION IS USEFULL TO FIX
   * A DUPLICAT MENU ID/KEY
   */
  // useEffect(() => {
  //   console.log('Duplicate menu keys is:', findDuplicates(getMenuIds(menus)))
  // }, [])

  useEffect(() => {
    if (userAcl && ready) {
      const menuKeys = userAcl.map((item) => item.menu) || []
      const hasAdminPermission = menuKeys.includes('admin')
      if (hasAdminPermission) {
        setFilteredMenu(menus)
      } else {
        const filtered = menus.filter(function filterMenu(o) {
          if (menuKeys.includes(o.url)) return true
          if (o.submenus) {
            return (o.submenus = o.submenus.filter(filterMenu)).length
          }
          return false
        })

        setFilteredMenu(filtered)
      }
    }
  }, [i18n.language, ready, userAcl])

  // const handleClickMenu = (id, url: string, disabled: boolean) => () => {
  //   if (disabled) {
  //     return
  //   }

  //   if (url.includes(router.pathname)) {
  //     router.reload()
  //   }

  //   localStorage.setItem('menu_id', id)
  // }
  // function renderMenus(menus) {
  //   return menus.map((item) => {
  //     const { name, id, submenus, url, hide, disabled } = item

  //     if (hide) return

  //     // Show submenu if any submenu is not hidden, usefull for generate breadcrumb
  //     if (
  //       submenus &&
  //       submenus.filter((f) => !f.hide && !f.hidePermission).length > 0
  //     ) {
  //       return (
  //         <SubMenu
  //           key={id}
  //           title={name}
  //           disabled={disabled}
  //           style={{ color: 'rgba(0, 0, 0, 0.25)' }}
  //         >
  //           {renderMenus(submenus)}
  //         </SubMenu>
  //       )
  //     }

  //     return (
  //       <Menu.Item
  //         key={id}
  //         disabled={disabled}
  //         onClick={handleClickMenu(id, url, disabled)}
  //       >
  //         <Link href={url}>
  //           <a
  //             style={{
  //               ...(disabled
  //                 ? { cursor: 'not-allowed', color: 'rgba(0, 0, 0, 0.25)' }
  //                 : {}),
  //             }}
  //           >
  //             {name}
  //           </a>
  //         </Link>
  //       </Menu.Item>
  //     )
  //   })
  // }

  const menuItems = useMemo(
    () => [...filteredMenu].map((menu) => createMenus(menu, currentUser)),
    [filteredMenu],
  )

  const handleClickMenu = (e) => {
    if (router.pathname === e.key && !router.query) {
      router.reload()
      return
    }

    localStorage.setItem('menu_id', e.key)
    router.push(e.key)
  }
  return (
    <div id={cssMenu.container}>
      <Menu
        mode="horizontal"
        items={menuItems}
        forceSubMenuRender
        subMenuCloseDelay={0.1}
        onClick={handleClickMenu}
      />
      {/* {renderMenus(filteredMenu)} */}
      {/* </Menu> */}
    </div>
  )
}

export default withRouter(MenuBar)
