import { get, uniqBy } from 'lodash'
import ApiCall from 'services/ApiCall'

type GenerateFetchOptionsParams = {
  searchText?: string
  value?: number
  model: string
  fields?: string[]
  limit?: number | boolean
  returnValue?: string
  domain?: any[]
  fieldKey?: string
  service?: any
}

/**
 * It takes a model name (from ApiCall), a search text, a value, and returns a list of options for a select component
 * @param  - searchText: The text that the user has typed in the search box.
 * @returns An array of objects with the following structure:
 * [
 *   {
 *     label: 'string',
 *     value: 'string'
 *   }
 * ]
 */
const generateFetchForOptions = async ({
  searchText,
  model,
  fields = [],
  limit = false,
  returnValue = 'id',
  domain = [],
  fieldKey = 'name',
  service = undefined,
}: GenerateFetchOptionsParams) => {
  const defaultDomain = [...domain, [fieldKey, 'ilike', searchText ?? '']]

  const apiModel = !service ? get(ApiCall, model) : service

  const res = await apiModel.search({
    domain: defaultDomain,
    limit,
    fields: [fieldKey, ...fields],
  })

  const dataSource = res?.records || res?.result?.records
  const options =
    dataSource?.map((rec) => ({
      label: rec[fieldKey],
      value: returnValue.includes('_id')
        ? rec[returnValue]?.[0]
        : rec[returnValue],
      original: rec,
    })) ?? []

  return uniqBy(options, 'value')
}

export default generateFetchForOptions
