import moment from 'moment'
import create from 'zustand'
import { devtools, persist } from 'zustand/middleware'

interface AccountClosingState {
  fiscalYear: string
  currentMonth: moment.Moment
  closedDate: moment.Moment | null
  isClosed: boolean

  setFiscalYear: (fiscalYear: string | number) => void
  setCurrentMonth: (currentMonth: moment.Moment) => void
  setClosedDate: (currentMonth: moment.Moment) => void
  setIsClosed: (isClosed: boolean) => void
}

const useAccountClosingStore = create<AccountClosingState>()(
  devtools(
    persist(
      (set) => ({
        fiscalYear: moment().format('YYYY'),
        currentMonth: moment().subtract(1, 'day'),
        isClosed: false,
        closedDate: null,

        setFiscalYear: (fiscalYear: string) => set(() => ({ fiscalYear })),
        setCurrentMonth: (currentMonth: moment.Moment) =>
          set(() => ({ currentMonth })),
        setClosedDate: (closedDate: moment.Moment) =>
          set(() => ({ closedDate })),
        setIsClosed: (isClosed: boolean) => set(() => ({ isClosed })),
      }),
      {
        name: 'account-closing-storage',
        deserialize: async (str) => {
          if (!str) return { state: {} }
          const k = JSON.parse(str)
          if (k.state.closedDate) {
            const _val = moment(k.state.closedDate)
            if (_val.isValid()) k.state.closedDate = _val
          }
          if (k.state.currentMonth) {
            const _val = moment(k.state.currentMonth)
            if (_val.isValid()) k.state.currentMonth = _val
          }
          return k
        },
      },
    ),
  ),
)

export default useAccountClosingStore
