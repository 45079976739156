import i18n from 'layouts/i18n'

const getTitleName = (
  dict: string | string[],
  accessor: string,
  name?: string,
) => {
  if (name) {
    if (i18n.exists(name)) {
      return i18n.t(name)
    }

    return name
  }

  if (Array.isArray(dict)) {
    return i18n?.t(`${dict?.[0]}:${accessor}`)
  }

  return name
}

export default getTitleName
