import moment from 'moment'

import { DateConfig } from 'components/Templates/Input/services/formatter'

const convertStartDate = (value: string | Date) => {
  if (!value) return ''
  const startDate = DateConfig.to_BE_DateString(moment(value).startOf('days'), {
    withTime: true,
    toUtc: true,
  })
  return startDate
}

const convertEndDate = (value: string | Date) => {
  if (!value) return ''
  const endDate = DateConfig.to_BE_DateString(moment(value).endOf('days'), {
    withTime: true,
    toUtc: true,
  })
  return endDate
}

export default {
  convertStartDate,
  convertEndDate,
}
