import Router from 'next/router'

import { notification } from 'antd'
import axios, { AxiosError, AxiosInstance } from 'axios'
import { BASE_API_URL } from 'constant'

import parseJWT from 'helpers/parseJWT'
import CODE from 'utils/constants/code.constant'
import PUBLIC_PAGES from 'utils/constants/page.constant'

import requestInterceptorList from './Interceptors/Request'

const getErrorMessage = async (error: any) => {
  const errorMessage =
    error?.config?.responseType === 'blob'
      ? JSON.parse(await error?.response?.data?.text())
      : {}
  return (
    errorMessage?.error?.message ||
    error?.response?.data?.errors ||
    error?.response?.data?.error?.message ||
    error?.response?.data?.message ||
    error?.response?.data?.error ||
    error?.message
  )
}

notification.config({
  maxCount: 1,
})
function showNotification(error: AxiosError) {
  const errorStatus = error?.response?.status || 400
  const errorCode = error?.response?.data?.code || '00'

  if (errorStatus === 503 && errorCode === CODE.MAINTENANCE) {
    const referer = Router.query.referer as string
    Router.push(`/maintenance?referer=${referer ? referer : Router.pathname}`)
    return
  }

  return (
    error?.response?.data?.errors ||
    error?.response?.data?.error?.message ||
    error?.response?.data?.message ||
    error?.response?.data?.error ||
    error?.message
  )
}

function resetAuthTokenRequest() {
  authTokenRequest = null
}

let authTokenRequest: Promise<any> | null
const getAuthToken = (formData, keyPin) => {
  if (!authTokenRequest) {
    authTokenRequest = axios.post(`${BASE_API_URL}/refresh_token/`, formData, {
      headers: {
        kid: keyPin,
      },
    })
    authTokenRequest.then(resetAuthTokenRequest, resetAuthTokenRequest)
  }

  return authTokenRequest
}

function createAuthAxios(
  baseURL: string,
  keyLocalStorage?: string,
  keyPin?: string,
): AxiosInstance {
  const instanceAxios = axios.create({
    baseURL: process.env.NODE_ENV === 'test' ? undefined : baseURL,
    withCredentials: true,
    timeout: 600000, // 10minute
  })

  if (keyLocalStorage && keyPin) {
    instanceAxios.interceptors.request.use((config) => {
      const curConfig = { ...config }

      // if (config.url?.includes('/search_read')) {
      //   if (!config.data?.params?.fields?.length) {
      //     console.warn('has no fields [PROPERTY]', config.url)
      //   }
      // }

      // if (config.url?.includes('/read')) {
      //   if (!config.data?.params?.args?.[1]?.length) {
      //     console.warn('has no fields [ARGS]', config.url)
      //   }
      //   if (!config.data?.params?.fields?.length) {
      //     console.warn('has no fields [PROPERTY]', config.url)
      //   }
      // }

      // ALWAYS READ UPDATED TOKEN
      try {
        curConfig.headers.Authorization = `Bearer ${localStorage.getItem(
          keyLocalStorage,
        )}`
        curConfig.headers.kid = localStorage.getItem(keyPin)
        // curConfig.headers['public-key-pins'] = localStorage.getItem(keyPin)
      } catch (e) {
        // console.log('AXIOS CATCH: ', e)
      }
      return curConfig
    })

    instanceAxios.interceptors.response.use(
      async (response) => {
        // const errorName = 'odoo.exceptions.AccessError'
        try {
          const refreshToken = localStorage.getItem('refresh-token')
          const parsedJwt = parseJWT(refreshToken)
          const keyPin = localStorage.getItem('key-pin')
          if (
            (!keyPin || !parsedJwt) &&
            !PUBLIC_PAGES.includes(Router.pathname)
          ) {
            window.location.replace(
              `/?prevPage=${window.location.pathname}${window.location.search}&error=token_deleted`,
            )
          }
        } catch (err) {
          //
        }

        // if (
        //   refreshToken &&
        //   keyPin &&
        //   response.data.error &&
        //   response.data.error.data.name === errorName &&
        //   !response.data.error.data.message.includes(
        //     'Due to security restrictions',
        //   ) &&
        //   !response.data.error.data.message.includes(
        //     'cannot be called remotely.',
        //   )
        // ) {
        //   const originalRequest = response.config as any
        //   originalRequest._retry = true
        //   const formData = new FormData()
        //   formData.append('refresh_token', refreshToken)
        //   try {
        //     const res = await axios.post(
        //       `${BASE_API_URL}/refresh_token`,
        //       formData,
        //       {
        //         headers: {
        //           kid: keyPin,
        //           // 'public-key-pins': keyPin,
        //         },
        //       },
        //     )
        //     if (res?.data?.error) {
        //       notification.error({
        //         message: res.data.error?.data?.message,
        //       })
        //       localStorage.removeItem('token-ldx')
        //       localStorage.removeItem('refresh-token')
        //       Router.reload()
        //     } else {
        //       const accessToken = res.data?.access_token
        //       originalRequest.headers.Authorization = `Bearer ${accessToken}`
        //       localStorage.setItem('token-ldx', res.data?.access_token)
        //       localStorage.setItem('refresh-token', res.data?.refresh_token)
        //       return instanceAxios(originalRequest)
        //     }
        //   } catch (err) {
        //     notification.error({
        //       message: 'Failed refresh token :(',
        //     })
        //     localStorage.removeItem('token-ldx')
        //     localStorage.removeItem('refresh-token')
        //     Router.reload()
        //   }
        // }
        return response
      },
      async (error) => {
        const isUrlAclFull = error.response?.config?.url?.includes('/acl/full')
        if (error?.message?.includes('timeout')) {
          return Promise.reject(error)
        }

        if (error.response?.status === 400) {
          return Promise.reject({
            code: error?.response?.data?.code || 1,
            message: await getErrorMessage(error),
          })
        }

        if (error.response?.status === 500 && !isUrlAclFull) {
          notification.error({
            message: await getErrorMessage(error),
          })
          return Promise.reject({
            code: 500,
            message: await getErrorMessage(error),
          })
        }

        const originalRequest = error.config
        const refreshToken = localStorage.getItem('refresh-token')
        const keyPin = localStorage.getItem('key-pin')
        const formData = new FormData()
        formData.append('refresh_token', refreshToken)

        if (
          (error.response?.status === 401 || error.response?.status === 403) &&
          !!refreshToken
        ) {
          originalRequest._retry = true
          return getAuthToken(formData, keyPin)
            .then((res) => {
              const accessToken = res.data?.access_token
              originalRequest.headers.Authorization = `Bearer ${accessToken}`
              localStorage.setItem('token-ldx', res.data?.access_token)
              localStorage.setItem('refresh-token', res.data?.refresh_token)

              return instanceAxios(originalRequest)
            })
            .catch((error) => {
              if (
                error?.response?.data?.errors ===
                  'Refresh token is invalid or expired' ||
                error?.response?.data?.error?.message ===
                  'Token or refresh token is invalid or expired'
              ) {
                localStorage.removeItem('token-ldx')
                localStorage.removeItem('refresh-token')
                localStorage.removeItem('key-pin')

                window.location.replace(
                  `/?prevPage=${window.location.pathname}${window.location.search}&error=token_expired`,
                )
              }
            })
        }
        return Promise.reject(error)
      },
    )
  }

  instanceAxios.interceptors.response.use(
    (response) => {
      return response
    },
    (error: AxiosError) => {
      if (!error.message?.includes('403') && !axios.isCancel(error)) {
        showNotification(error)
      }
      return Promise.reject(error)
    },
  )

  // Flexible Request Interceptors
  requestInterceptorList.forEach((hook) =>
    instanceAxios.interceptors.request.use(hook),
  )

  return instanceAxios
}

const Fetcher = {
  createAuthAxios,
}

export default Fetcher
