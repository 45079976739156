import React from 'react'
import Content from 'components/Content/Content'
import { Col, Row } from 'antd'
import Text from 'components/Typography/Text'

function Footer() {
  const dateNow = new Date()
  const yearNow = dateNow.getFullYear()

  return (
    <Content
      id={'contributors'}
      component={(props) => <footer {...props} />}
      style={{
        padding: 16,
        color: '#fafafa',
        borderTop: '1px solid #003288',
      }}
    >
      <Row gutter={[0, 16]}>
        <Col xs={24} style={{ textAlign: 'center' }}>
          <div>
            <Text
              size={14}
              style={{
                color: '#003288',
              }}
            >
              Copyright &copy;&nbsp;
              {yearNow}
              &nbsp;L-DX Co., Ltd. All Rights Reserved
            </Text>
          </div>
        </Col>
      </Row>
    </Content>
  )
}

export default Footer
