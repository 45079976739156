import ApiCall from 'services/ApiCall'

export const MAP_LANGUAGE = {
  en: 'en_US',
  ja: 'ja_JP',
}

export type GetTranslatableFields = {
  model: string
  id: string | number
  field: string
  lang?: 'en_US' | 'ja_JP'
}

interface ReturnGetTranslatableFields {
  name: string
  res_model: string
  type: string
  view_mode: string
  view_id: number[]
  target: string
  flags: Flags
  domain: Array<Array<number | string> | string>
  context: Context
  views: Array<Array<number | string>>
}

interface Context {
  search_default_name: string
  translation_type: string
  translation_show_src: boolean
}

interface Flags {
  search_view: boolean
  action_buttons: boolean
}

export interface TranslatableFieldSearchRead {
  id: number
  lang: string
  src: string
  value: string
}

const languageService = {
  // ...ApiCall._generateCrud('res.lang'),
  getInstalledLanguage: (): Promise<{ key: string; value: string }[]> =>
    ApiCall.api
      .post('/dataset/res.lang/get_installed/', {
        params: {
          args: [],
          kwargs: {},
        },
      })
      .then((res) => res.data)
      .then((res) => res?.result ?? [])
      .then((res) => {
        return res.map((t) => ({
          key: t[0],
          value: t[1],
        }))
      }),

  getTranslatableFields: async (
    { model, id, field, lang }: GetTranslatableFields,
    availableLang?: string[],
  ): Promise<ReactQueryResponse<TranslatableFieldSearchRead>> => {
    const res = await ApiCall.api
      .post<ReadResponse<ReturnGetTranslatableFields>>(
        '/dataset/ir.translation/translate_fields/',
        {
          params: {
            args: [model, id, field],
            kwargs: {},
          },
        },
      )
      .then((res) => res.data?.result)

    const domain: (string | (string | number | string[])[])[] = [
      ...res.domain,
      ['name', '=', res.context.search_default_name],
    ]

    if (availableLang) {
      domain.push(['lang', 'in', availableLang])
    }

    return ApiCall.api
      .post('/dataset/ir.translation/search_read/', {
        params: {
          domain,
          fields: ['lang', 'src', 'value'],
          kwargs: {
            // context: {
            //   lang,
            // },
          },
        },
      })
      .then((res) => res.data)
  },

  updateTranslate: (id: number | number[], data: { value: string }) =>
    ApiCall._generateCrud('ir.translation').write(id, data),
}

export default languageService
