import React from 'react'
import { useTranslation } from 'react-i18next'

import { ConfigProvider, DatePicker } from 'antd'
import { RangePickerProps } from 'antd/lib/date-picker'
import ja_JP from 'antd/lib/locale-provider/ja_JP'
import useTitleAndError, {
  UseTitleAndErrorProps,
} from 'fields/useTitleAndError/useTitleAndError'
import { useField } from 'formik'

import { FORMAT_DATE } from 'utils/constants'

import 'moment/locale/ja'

const { RangePicker } = DatePicker

type FRangeProps = RangePickerProps & UseTitleAndErrorProps
function FRangePicker(props: FRangeProps) {
  const { t, i18n } = useTranslation('common')
  const [field, , helpers] = useField(props as any)
  const [title, error] = useTitleAndError(props)

  return (
    <React.Fragment>
      {title}
      {i18n.language === 'ja' ? (
        <ConfigProvider locale={ja_JP}>
          <RangePicker
            {...field}
            onBlur={() => {
              helpers.setTouched(true)
            }}
            onChange={(value) => {
              helpers.setValue(value)
            }}
            format={FORMAT_DATE}
            placeholder={[t('common:startDate'), t('common:endDate')]}
            {...props}
          />
        </ConfigProvider>
      ) : (
        <RangePicker
          {...field}
          onBlur={() => {
            helpers.setTouched(true)
          }}
          onChange={(value) => {
            helpers.setValue(value)
          }}
          format={FORMAT_DATE}
          placeholder={[t('common:startDate'), t('common:endDate')]}
          {...props}
        />
      )}
      {error}
    </React.Fragment>
  )
}

export default FRangePicker
