import { useCallback, useMemo } from 'react'

import { Button, Form } from 'antd'

const useCopyRow = ({ disabled }) => {
  const handleCopyRow = useCallback(
    (getFieldValue, setFieldsValue, rowIndex) => () => {
      const products = getFieldValue('products')
      const newProducts = [...products]

      newProducts.splice(rowIndex + 1, 0, {
        ...products[rowIndex],
        id: null,
        is_copy: true,
        copied_from: rowIndex,
      })
      setFieldsValue({
        products: newProducts,
      })
    },
    [],
  )

  const copyRow = useMemo(
    () => ({
      key: 'copy_row',
      title: ' ',
      dataIndex: 'id',
      width: 70,
      delete: false,
      align: 'center' as any,
      render: (_, _rec, rowIndex) => {
        return (
          <Form.Item noStyle shouldUpdate>
            {({ getFieldValue, setFieldsValue }) => (
              <Button
                onClick={handleCopyRow(getFieldValue, setFieldsValue, rowIndex)}
                disabled={disabled}
              >
                copy
              </Button>
            )}
          </Form.Item>
        )
      },
    }),
    [handleCopyRow, disabled],
  )

  return {
    column: copyRow,
  }
}
export default useCopyRow
