import { Fragment, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { CloseOutlined } from '@ant-design/icons'
import { Button, Col, Popover, Row, Spin } from 'antd'
import { TooltipPlacement } from 'antd/lib/tooltip'
import ApiCall from 'services/ApiCall'

export function CustomRow({ children }: { children: any }) {
  return (
    <Row
      gutter={0}
      style={{
        borderBottom: 2,
        borderBottomColor: 'rgba(32,30,30,.1)',
        borderBottomStyle: 'solid',
        padding: '2px 10px 2px',
      }}
    >
      {children}
    </Row>
  )
}
interface ProductDetailPopUpProps {
  click?: boolean
  hover?: boolean
  children?: any
  productID: number
  placement?: TooltipPlacement
  imageObjectFit?: 'fill' | 'cover' | 'contain' | 'none' | 'scale-down'
  width?: number
  imageHeight?: number
  isSKU?: boolean
  productCode?: string
}

export default function ProductDetailPopUp({
  click = true,
  hover,
  children,
  productID,
  placement,
  imageObjectFit = 'contain',
  width,
  imageHeight,
  isSKU = true,
  productCode,
}: ProductDetailPopUpProps) {
  const { t } = useTranslation(['product_master_registration', 'common'])
  const [visible, setVisible] = useState(false)
  const [data, setData] = useState<any>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [imageIsLoading, setImageIsLoading] = useState(false)
  const [images, setImages] = useState<
    { src: string; is_thumbnail: boolean }[]
  >([{ src: '/images/no-image.png', is_thumbnail: false }])
  useEffect(() => {
    if (visible) {
      if (data === null) {
        setIsLoading(true)
        ApiCall[isSKU ? 'Product' : 'ProductTemplate']
          .get(productID, {
            ...((isSKU
              ? {
                  fields: [
                    'product_code',
                    'name',
                    'color_id',
                    'size_id',
                    'retail_price',
                    'thumbnail_id',
                    'product_image_ids',
                  ],
                }
              : {
                  fields: [
                    'product_code',
                    'name',
                    'retail_price',
                    'thumbnail_id',
                    'deployed_colors',
                    'deployed_sizes',
                    'product_image_ids',
                  ],
                }) as any),
          })
          .then((resp) => {
            setIsLoading(false)
            if (resp.result?.length) {
              const _data = resp.result[0]
              setData(_data)
              if (_data.product_image_ids?.length <= 0) {
                return
              }

              setImageIsLoading(true)
              ApiCall.ProductImage.search({
                domain: [['id', 'in', _data?.product_image_ids]],
                fields: ['image', 'is_thumbnail'],
              })
                .then((res) => {
                  if (res?.length > 0) {
                    setImages(
                      res?.records?.map((data) => ({
                        src: `data:image/png;base64,${data.image}`,
                        is_thumbnail: data?.is_thumbnail,
                      })),
                    )
                  }
                })
                .finally(() => {
                  setImageIsLoading(false)
                })
            } else {
              setData('not-found')
            }
          })
          .catch(() => {
            setIsLoading(false)
            setData('error')
          })
      }
    }
  }, [visible, data])
  const triggers: string[] = []
  if (click) {
    triggers.push('click')
  }
  if (hover) {
    triggers.push('hover')
  }
  function getThumbnailImage(): string {
    const _thumbnail = images.find((k) => k.is_thumbnail)
    if (_thumbnail) {
      return _thumbnail.src
    }
    return images[0].src
  }
  function renderContent() {
    if (isLoading) {
      return <Spin />
    }
    if (
      [null, 'not-found', 'error'].includes(data) ||
      productCode !== data?.product_code ||
      ['material', 'service'].includes(data?.type)
    ) {
      return children
    }

    return (
      <Fragment>
        {!hover && (
          <Row justify="end">
            <Col>
              <Button
                type="text"
                shape="circle"
                htmlType="button"
                icon={<CloseOutlined />}
                onClick={() => setVisible(false)}
              />
            </Col>
          </Row>
        )}
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: width ?? 300,
            padding: 10,
          }}
        >
          {imageIsLoading ? (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Spin />
            </div>
          ) : (
            <img
              style={{
                width: '100%',
                height: imageHeight ?? 200,
                objectFit: imageObjectFit,
              }}
              alt=""
              src={getThumbnailImage()}
            />
          )}
          <div style={{ width: '100%', height: 20 }} />
          <CustomRow>
            <Col sm={11}>{t('common:productCode')}</Col>
            <Col sm={1}>:</Col>
            <Col sm={12}>{data?.product_code}</Col>
          </CustomRow>
          <CustomRow>
            <Col sm={11}>{t('common:productName')}</Col>
            <Col sm={1}>:</Col>
            <Col sm={12}>{data?.name}</Col>
          </CustomRow>
          <CustomRow>
            <Col sm={11}>{t('color')}</Col>
            <Col sm={1}>:</Col>
            <Col sm={12}>
              {isSKU ? data?.color_id?.[1] : data?.deployed_colors}
            </Col>
          </CustomRow>
          <CustomRow>
            <Col sm={11}>{t('size')}</Col>
            <Col sm={1}>:</Col>
            <Col sm={12}>
              {isSKU ? data?.size_id?.[1] : data?.deployed_sizes}
            </Col>
          </CustomRow>
          <CustomRow>
            <Col sm={11}>{t('assumptionRetailPrice')}</Col>
            <Col sm={1}>:</Col>
            <Col sm={12}>
              {`${data?.retail_price}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            </Col>
          </CustomRow>
        </div>
      </Fragment>
    )
  }
  return (
    <Popover
      content={renderContent()}
      visible={visible}
      onVisibleChange={setVisible}
      trigger={triggers}
      placement={placement}
    >
      {children}
    </Popover>
  )
}
