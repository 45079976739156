import { useQuery, UseQueryOptions } from 'react-query'

import { languageService } from 'services'

const useGetAvailableLang = (
  options?: UseQueryOptions<{ key: string; value: string }[]>,
) => {
  const { data, isLoading, isFetching } = useQuery(
    ['GetAllLanguage'],
    languageService.getInstalledLanguage,
    options,
  )

  return {
    data: data || [],
    loading: isLoading || isFetching,
  }
}

export default useGetAvailableLang
