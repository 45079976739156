import { useQuery, UseQueryOptions } from 'react-query'

import { languageService } from 'services'
import {
  GetTranslatableFields,
  TranslatableFieldSearchRead,
} from 'services/language.service'

import useGetAvailableLang from 'data/Language/useGetAvailableLang'

const useGetTranslatableField = (
  params: GetTranslatableFields,
  options?: UseQueryOptions<{
    records: TranslatableFieldSearchRead[]
    length: number
  }>,
) => {
  const { data: dataLang } = useGetAvailableLang()
  const availableLang = dataLang?.map((lang) => lang.key)

  const { data, isFetching, isLoading } = useQuery(
    ['GetTranslatableFields', params],
    async () => {
      const res = await languageService.getTranslatableFields(
        params,
        availableLang,
      )

      return res?.result
    },
    {
      enabled: availableLang.length > 0,
      ...options,
    },
  )

  return {
    data,
    loading: isFetching || isLoading,
  }
}
export default useGetTranslatableField
