import {
  createContext,
  FC,
  Fragment,
  useContext,
  useEffect,
  useState,
} from 'react'

import { useRouter } from 'next/router'

import find from 'lodash/find'

import useSocketIO from 'data/useSocketIO'
import useUserACL from 'data/useUserACL'

import ErrorAccessPermissionView from 'components/ErrorAccessPermissionView'
import VerifyingLoading from 'components/Loading/VerifyingLoading'
import PUBLIC_PAGES from 'utils/constants/page.constant'

export const PermissionContext = createContext({
  hasPageAccess: false,
  hasApproveAccess: false,
  hasReadAccess: false,
  hasWriteAccess: false,
  setPageKey: (_e: string) => {
    return
  },
  socketIO: null,
})

export const usePermission = () => {
  return useContext(PermissionContext)
}

const PermissionContextProvider: FC = ({ children }) => {
  const router = useRouter()
  const [hasPageAccess, setPageAccess] = useState(false)
  const [hasApproveAccess, setApproveAccess] = useState(false)
  const [hasWriteAccess, setWriteAccess] = useState(false)
  const [hasReadAccess, setReadAccess] = useState(false)
  const [isEverythingReady, setEverythingReady] = useState(false)
  const [pageKey, setPageKey] = useState('')
  const qUserACLs = useUserACL()
  const socketIO = useSocketIO({
    enabled:
      router.pathname ===
      '/in-season-management/sales-linkage-for-wholesale-sales/exhibition-order-monitoring',
  })

  useEffect(() => {
    if (qUserACLs.isFetched && router.isReady) {
      if (!qUserACLs.isSuccess) return setEverythingReady(true)
      const adminACL = find(qUserACLs.data, { menu: 'admin' })
      const acl = find(qUserACLs.data, { menu: pageKey || router.route })

      if (acl) {
        setPageAccess(true)
        setReadAccess(acl.access_level === 'read')
        setWriteAccess(
          acl.access_level === 'approve' || acl.access_level === 'write',
        )

        setApproveAccess(acl.access_level === 'approve')
      }

      if (adminACL) {
        setPageAccess(true)
        setReadAccess(adminACL.access_level === 'read')
        setWriteAccess(
          adminACL.access_level === 'approve' ||
            adminACL.access_level === 'write',
        )

        setApproveAccess(adminACL.access_level === 'approve')
      }

      if (!acl && !adminACL) {
        setPageAccess(false)
        setReadAccess(false)
        setWriteAccess(false)
        setApproveAccess(false)
      }
      setEverythingReady(true)
    }
  }, [qUserACLs.isFetched, router.isReady, router.route, pageKey])

  if (!isEverythingReady && !PUBLIC_PAGES.includes(router.pathname)) {
    return <VerifyingLoading />
  }

  if (router.pathname === '/home') {
    return <Fragment>{children}</Fragment>
  }

  if (!hasPageAccess && !PUBLIC_PAGES.includes(router.pathname)) {
    return <ErrorAccessPermissionView />
  }

  return (
    <PermissionContext.Provider
      value={{
        hasApproveAccess,
        hasPageAccess,
        hasReadAccess,
        hasWriteAccess,
        setPageKey,
        socketIO: socketIO.data,
      }}
    >
      {children}
    </PermissionContext.Provider>
  )
}

export default PermissionContextProvider
