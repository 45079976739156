import React from 'react'

import SyncOutlined from '@ant-design/icons/SyncOutlined'
import { Spin } from 'antd'

import cssLoading from './Loading.module.scss'

const Loading = React.forwardRef<HTMLDivElement>((props, ref) => {
  const antIcon = <SyncOutlined style={{ fontSize: 40 }} spin />

  return (
    <div
      data-testid="verifyLoading"
      data-cy="verifyLoading"
      className={cssLoading.loading}
      ref={ref}
    >
      <Spin indicator={antIcon} />
      <p>Verifying...</p>
    </div>
  )
}) as any

export default Loading
