import { UseQueryOptions } from 'react-query'

import useSearchQuery from 'hooks/Query/useSearchQuery'

import { ClosingHistory } from 'views/InventoryControl/ClosingProcessSetting/ClosingProcessSetting.types'
import changeLockDateService from 'views/InventoryControl/ClosingProcessSetting/services/changeLockDateService'

type ClosingHistoryData = {
  records: ClosingHistory[]
  length: number
}
const fetchClosingHistory = async ({ queryKey }) => {
  const res = await changeLockDateService.History.search<ClosingHistory>(
    queryKey[2],
  )

  return res
}
const useClosingHistory = (
  params?: SearchParams,
  options?: UseQueryOptions<ClosingHistoryData>,
) => {
  return useSearchQuery(fetchClosingHistory, params, options)
}

export default useClosingHistory
