import { useQuery, UseQueryOptions } from 'react-query'

import io from 'socket.io-client'

const socketIO = io

type TQueryFnData = any

function useSocketIO(options?: UseQueryOptions<TQueryFnData>) {
  const query = useQuery<TQueryFnData>(
    ['socket-io'],
    () => {
      return socketIO.io(process.env.SOCKET_IO_URL, {
        transports: ['websocket'],
        reconnection: true,
        reconnectionAttempts: 1,
        reconnectionDelay: 2000,
        query: {
          token:
            typeof window !== 'undefined'
              ? localStorage.getItem('token-ldx')
              : null,
          refresh_token:
            typeof window !== 'undefined'
              ? localStorage?.getItem('refresh-token')
              : null,
        },
      })
    },
    {
      staleTime: Infinity,
      cacheTime: Infinity,
      ...options,
    },
  )

  return {
    ...query,
    data: query.data,
  }
}

export default useSocketIO
