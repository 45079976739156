import type { Moment } from 'moment'
import moment from 'moment'

import { FORMAT_DATE, FORMAT_DATETIME } from 'utils/constants'

function utcToLocal(value: string | Date | Moment, timezone = ''): any {
  if (!value || value === '-') return ''
  if (timezone) {
    return moment.utc(value).utcOffset(timezone)
  }
  return moment.utc(value).local().format(FORMAT_DATETIME)
}

// convert date from BE to local time -> All Date from BE is UTC format, so need to convert it to user local time
export function utcToLocalNoTime(
  value: string | Date | Moment,
  timezone = '',
): any {
  if (!value || value === '-') return ''
  if (timezone) {
    return moment.utc(value).utcOffset(timezone)
  }
  return moment.utc(value).local().format(FORMAT_DATE)
}

export default utcToLocal
