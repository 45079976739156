import React from 'react'
import { useTranslation } from 'react-i18next'

import { Col, Input as AntInput, Row } from 'antd'
import { InputProps } from 'antd/lib/input'
import useTitleAndError, {
  UseTitleAndErrorProps,
} from 'fields/useTitleAndError/useTitleAndError'
import type { FormikContextType } from 'formik'
import { useField } from 'formik'

import { useMultiLanguage } from 'data/Language'

import { Input } from 'components/forms'

export type FInputProps = InputProps &
  UseTitleAndErrorProps & {
    name: string
    inline?: boolean
    /**
     * Formik Context
     */
    formik?: FormikContextType<any>

    /**
     * Multilanguage support
     */
    multiLanguage?: {
      title?: string
      model: string
      modelId?: string | number
      field?: string
      multiLanguageValues?: any
    }
  }

function FInput({ multiLanguage, ...props }: FInputProps) {
  const { t } = useTranslation(['common'])
  const [field] = useField(props as any)
  const [title, error] = useTitleAndError(props)
  const { inline, type } = props

  /**
   * Hooks for multilanguage
   */
  const { model: modelMultilanguage, formikOnSubmit } = useMultiLanguage(
    multiLanguage?.model,
    { enabled: !!multiLanguage },
  )

  if (type === 'password') {
    return (
      <React.Fragment>
        {title}
        <AntInput.Password
          id={props.id || props.name}
          placeholder={t('common:input')}
          {...field}
          {...props}
        />
        {error}
      </React.Fragment>
    )
  }

  if (inline) {
    return (
      <Row align="middle" gutter={10}>
        {title}
        <Col xs={13}>
          <Input
            id={props.id || props.name}
            placeholder={t('common:input')}
            {...field}
            {...props}
            multiLanguage={
              multiLanguage && {
                ...multiLanguage,
                field: multiLanguage.field || props.name,
                title: multiLanguage.title || props.title,
                model: modelMultilanguage,
                onSubmit: formikOnSubmit(props.formik, props.name),
              }
            }
          />
        </Col>
        {error}
      </Row>
    )
  }

  return (
    <React.Fragment>
      {title}
      <Input
        id={props.id || props.name}
        placeholder={t('common:input')}
        {...field}
        {...props}
        multiLanguage={
          multiLanguage && {
            ...multiLanguage,
            field: multiLanguage.field || props.name,
            title: multiLanguage.title || props.title,
            model: modelMultilanguage,
            onSubmit: formikOnSubmit(props.formik, props.name),
          }
        }
      />
      {error}
    </React.Fragment>
  )
}

export default FInput
