function getTitle(t: any, router: any, menus: any[], identifier?: boolean) {
  if (router === '/home') {
    if (identifier) {
      return 'Home'
    }
    return t('common:home')
  }
  if (router === '/notification') {
    if (identifier) {
      return 'Notification'
    }
    return t('common:notification')
  }

  if (router === '/notification') {
    if (identifier) {
      return 'Notification'
    }
    return t('common:notification')
  }

  if (
    router?.includes(
      '/in-season-management/sales-linkage-for-wholesale-sales/exhibition-order-management',
    ) ||
    router?.includes(
      '/in-season-management/sales-linkage-for-wholesale-sales/exhibition-order-details',
    )
  ) {
    if (identifier) {
      return 'Exhibition Order Management'
    }
    return t('common:exhibition_order_management')
  }

  if (menus?.length) {
    for (let i = 0; i < menus.length; i += 1) {
      const { submenus: submenusI, url, name } = menus[i]
      for (let j = 0; j < submenusI?.length; j += 1) {
        const { submenus: submenusJ, url, name } = submenusI[j]
        for (let k = 0; k < submenusJ?.length; k += 1) {
          const { url, name, submenus: submenusK } = submenusJ[k]
          for (let l = 0; l < submenusK?.length; l += 1) {
            const { url, name } = submenusK[l]
            // submenusL
            if (router === url) {
              return name
            }
          }
          // submenusK
          if (router === url) {
            return name
          }
        }
        // submenusJ
        if (router === url) {
          return name
        }
      }
      // submenusI
      if (router === url) {
        return name
      }
    }
  }

  return ''
}

export default getTitle
