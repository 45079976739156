import React from 'react'

import { Col, Row } from 'antd'
import { TextAreaProps } from 'antd/lib/input'
import useTitleAndError, {
  UseTitleAndErrorProps,
} from 'fields/useTitleAndError/useTitleAndError'
import { FormikContextType, useField } from 'formik'

import { useMultiLanguage } from 'data/Language'

import { Input } from 'components/forms'

export type FTextAreaProps = TextAreaProps &
  UseTitleAndErrorProps & {
    name: string
    inline?: boolean
    /**
     * Formik Context
     */
    formik?: FormikContextType<any>
    multiLanguage?: {
      title?: string
      model: string
      modelId?: string | number
      field?: string
      multiLanguageValues?: any
    }
  }

function FTextArea(props: FTextAreaProps) {
  const [field] = useField(props as any)
  const [title, error] = useTitleAndError(props)
  const { inline, multiLanguage } = props
  /**
   * Hooks for multilanguage
   */
  const { model: modelMultilanguage, formikOnSubmit } = useMultiLanguage(
    multiLanguage?.model,
    { enabled: !!multiLanguage },
  )

  if (inline) {
    return (
      <Row align="middle" gutter={10}>
        {title}
        <Col xs={13}>
          <Input
            rows={5}
            {...field}
            {...props}
            isTextArea
            multiLanguage={
              multiLanguage && {
                ...multiLanguage,
                field: multiLanguage.field || props.name,
                title: multiLanguage.title || props.title,
                model: modelMultilanguage,
                onSubmit: formikOnSubmit(props.formik, props.name),
              }
            }
          />
        </Col>
        {error}
      </Row>
    )
  }

  return (
    <React.Fragment>
      {title}
      <Input
        rows={5}
        {...field}
        {...props}
        isTextArea
        multiLanguage={
          multiLanguage && {
            ...multiLanguage,
            field: multiLanguage.field || props.name,
            title: multiLanguage.title || props.title,
            model: modelMultilanguage,
            onSubmit: formikOnSubmit(props.formik, props.name),
          }
        }
      />
      {error}
    </React.Fragment>
  )
}

export default FTextArea
