import React from 'react'
import { useTranslation } from 'react-i18next'

import { Col, InputNumber, Row } from 'antd'
import { InputNumberProps } from 'antd/lib/input-number'
import useTitleAndError, {
  UseTitleAndErrorProps,
} from 'fields/useTitleAndError/useTitleAndError'
import { FormikContextType, useField } from 'formik'

export type FInputNumberProps = InputNumberProps &
  UseTitleAndErrorProps & {
    name: string
    inline?: boolean
    /**
     * Formik Context
     */
    formik?: FormikContextType<any>
  }

function FInputNumber(props: FInputNumberProps) {
  const { t } = useTranslation('common')
  const [field, , helpers] = useField(props as any)
  const [title, error] = useTitleAndError(props)
  const { inline } = props
  if (inline) {
    return (
      <Row align="middle" gutter={10}>
        {title}
        <Col xs={13}>
          <InputNumber
            {...field}
            onBlur={() => {
              helpers.setTouched(true)
            }}
            onChange={(value) => {
              const target = { value }
              helpers.setValue(target)
            }}
            placeholder={t('common:input')}
            {...props}
          />
        </Col>
        {error}
      </Row>
    )
  }
  return (
    <React.Fragment>
      {title}
      <InputNumber placeholder={t('common:input')} {...field} {...props} />
      {error}
    </React.Fragment>
  )
}

export default FInputNumber
