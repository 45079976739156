import React from 'react'

import { Checkbox } from 'antd'
import { CheckboxProps } from 'antd/lib/checkbox'
import useTitleAndError, {
  UseTitleAndErrorProps,
} from 'fields/useTitleAndError/useTitleAndError'
import { FieldProps } from 'formik'

type NumberProps = CheckboxProps &
  FieldProps &
  UseTitleAndErrorProps & {
    isNegation?: boolean
  }
const ComField = (formikProps: NumberProps) => {
  const { field, form, ...props } = formikProps
  const [title, error] = useTitleAndError(props)
  const { value, ...others } = field

  // console.log('CHECKBOX: ', value)

  return (
    <React.Fragment>
      {title}
      <Checkbox
        {...others}
        {...props}
        checked={value}
        onChange={(e) => form.setFieldValue(field.name, e.target.checked)}
      />
      {error}
    </React.Fragment>
  )
}

export default ComField
