import { AxiosRequestConfig } from 'axios'

import { getCurrentLang } from 'data/Language/useGetCurrentLang'

import { getLocalTimeZoneName } from 'helpers/Utils'

export default function addLangAndTzInHeaders(config: AxiosRequestConfig) {
  const curConfig = { ...config }
  const lang = getCurrentLang()
  const tz = getLocalTimeZoneName()
  if (lang) {
    curConfig.headers['ldx-lang'] = getCurrentLang()
  }
  if (tz) {
    curConfig.headers['ldx-tz'] = getLocalTimeZoneName()
  }
  return curConfig
}
