import ApiCall from 'services/ApiCall'

import { getCurrentLang } from 'data/Language/useGetCurrentLang'

import API_ROUTE from 'utils/constants/api.constant'

const changeLockDateService = {
  History: ApiCall._generateCrud('change.lock.date.history'),

  /* Calling the API to close the period. */
  ClosePeriod: async (date: string) => {
    await ApiCall.api.post(API_ROUTE.CHANGE_LOCK_DATE.ACTION_CLOSE_PERIOD, {
      params: {
        args: [
          {
            date,
          },
        ],
        kwargs: {
          context: {
            lang: getCurrentLang(),
          },
        },
      },
    })
  },
  /* Opening the period. */
  OpenPeriod: async () => {
    await ApiCall.api.post(API_ROUTE.CHANGE_LOCK_DATE.ACTION_OPEN_PERIOD, {
      params: {
        args: [],
        kwargs: {
          context: {
            lang: getCurrentLang(),
          },
        },
      },
    })
  },
  /* Checking if the date is closed or not. */
  IsPeriodClose: async (date: string) => {
    await ApiCall.api.post(API_ROUTE.CHANGE_LOCK_DATE.IS_DATE_PERIOD_CLOSE, {
      params: {
        args: [date],
        kwargs: {
          context: {
            lang: getCurrentLang(),
          },
        },
      },
    })
  },
}

export default changeLockDateService

export const changeLockDateDefaultFields = [
  'fiscal_year_date_start_on_executed',
  'create_date',
  'write_uid',
  'period_lock_date',
  'action_state',
  'before_period_lock_date',
]
