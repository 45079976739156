// rewrite bcs found some bug https://github.com/mswjs/msw/issues/1227#issuecomment-1118492523

export async function initMSW() {
  if (typeof window === 'undefined') {
    const { server } = await import('./server')

    server.listen()
  } else {
    const { worker } = await import('./browser')
    worker.start()
  }
}

initMSW()
