import React from 'react'

import { Col, Form, Row } from 'antd'

import { Input } from 'components/Templates/Input'

type Props = {
  startName: string
  endName: string
  min?: number
  max?: number
  suffix?: string
}
/**
 * Use this only with Antd Form
 * @returns
 */
const InputRange: React.FC<Props> = ({
  startName,
  endName,
  min,
  max,
  suffix,
}): JSX.Element => {
  return (
    <Row gutter={0}>
      <Col span={10} className="flex items-center">
        <Form.Item noStyle name={startName}>
          <Input.Number
            style={{
              width: '100%',
              textAlign: 'center',
              borderRightWidth: 0,
            }}
            placeholder="Minimum"
            min={min}
            max={max}
          />
        </Form.Item>
        {<span>{suffix}</span>}
      </Col>
      <Col span={4}>
        <Input
          className="site-input-split"
          style={{
            width: '100%',
            borderLeft: 0,
            borderRight: 0,
            pointerEvents: 'none',
            backgroundColor: '#fff',
          }}
          placeholder="~"
          disabled
        />
      </Col>
      <Col span={10} className="flex items-center">
        <Form.Item noStyle shouldUpdate>
          {({ getFieldValue }) => (
            <Form.Item noStyle name={endName}>
              <Input.Number
                className="site-input-right"
                style={{
                  width: '100%',
                  textAlign: 'center',
                  borderLeftWidth: 0,
                }}
                placeholder="Maximum"
                min={getFieldValue(startName)}
                max={max}
              />
            </Form.Item>
          )}
        </Form.Item>
        {<span>{suffix}</span>}
      </Col>
    </Row>
  )
}

export default InputRange
