import React from 'react'
import { useTranslation } from 'react-i18next'

function ErrorAccessPermissionView() {
  const { t } = useTranslation(['common'])
  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      {t('common:error_access_permission_view')}
    </div>
  )
}

export default ErrorAccessPermissionView
