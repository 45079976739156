import Link from 'next/link'

import { Button, Tooltip } from 'antd'
import { isNil } from 'lodash'

import utcToLocal, { utcToLocalNoTime } from 'helpers/utcToLocal'
import { formatCurrency } from 'utils/helpers/formatter'

import { ColumnType } from '../types'

type Props = {
  column: ColumnType<any>
  detail?: {
    url: string
    label: string
  }
  onClickDetail?: (r: any) => string | { href: string; as: string }
  onRowClick?: (r: any) => void
  toFixed?: number
}
const renderColumn = (props: Props) => (v: any, r: any) => {
  if (!v && v !== 0) {
    return null
  }

  const { column, detail, onClickDetail, onRowClick, toFixed = 2 } = props
  let value = v

  if (column.editable) {
    if ((isNil(value) || value === 'null' || !value) && value !== 0) {
      return null
    }

    return value
  }

  if (column.key === 'select_action') {
    let href = `${detail.url}${value}`
    let as = ''
    if (onClickDetail) {
      const detailUrl = onClickDetail(r)
      if (typeof detailUrl === 'string') {
        href = detailUrl
        as = detailUrl
      } else {
        href = detailUrl.href
        as = detailUrl.as
      }
    }

    return onRowClick ? (
      <Button onClick={() => onRowClick(r)} type="primary" block>
        {detail.label}
      </Button>
    ) : (
      <Link href={href} passHref as={as}>
        <Button type="primary" block>
          {detail.label}
        </Button>
      </Link>
    )
  }

  if (column.format === 'currency') {
    value = formatCurrency(v, toFixed)
  }

  /**
   * Please use format
   */
  // if (
  //   v &&
  //   (moment(v, FORMAT_BACKEND_DATE, true).isValid() ||
  //     moment(v, FORMAT_BACKEND_DATETIME, true).isValid())
  // ) {
  //   value = utcToLocalNoTime(v)
  // }

  if (column.format === 'date') {
    value = utcToLocalNoTime(v)
  }

  if (column.format === 'datetime') {
    value = utcToLocal(v)
  }

  if (Array.isArray(v)) {
    value = v[1] as unknown as any
  }

  if (column.format === 'percentage') {
    value = typeof v === 'number' ? `${(v * 100).toFixed(2)}%` : null
  }

  return <Tooltip title={value}>{value}</Tooltip>
}

export default renderColumn
