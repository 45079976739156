import React from 'react'
import { useTranslation } from 'react-i18next'

import type { DatePickerProps } from 'antd'
import locale from 'antd/lib/date-picker/locale/ja_JP'
import useTitleAndError, {
  UseTitleAndErrorProps,
} from 'fields/useTitleAndError/useTitleAndError'
import type { FormikProps } from 'formik'
import { connect, useField } from 'formik'
import moment from 'moment'

import { DatePicker } from 'components/forms'
import utcToLocal from 'helpers/utcToLocal'

import 'moment/locale/ja'

// moment.locale('ja')

export type FDatePickerProps = DatePickerProps &
  UseTitleAndErrorProps & {
    containerStyle?: any
    saveToHistory?: boolean
    onChangeHandler?: (v?: moment.Moment) => void
    lockDate?: boolean
    formik?: FormikProps<any>
  }

function FDatePicker({ style, lockDate = true, ...props }: FDatePickerProps) {
  const { t, i18n } = useTranslation()
  const [field, meta, helpers] = useField(props as any)
  const [title, error] = useTitleAndError(props)

  const formName = props?.formik?.values?.form_name
  function renderContent() {
    return (
      <React.Fragment>
        {title}
        <DatePicker
          placeholder={t('common:select_date')}
          lockDate={lockDate && formName !== 'filterForm'}
          {...field}
          {...props}
          onChange={(value) => {
            if (props.onChangeHandler) {
              props.onChangeHandler(value)
            } else {
              helpers.setValue(utcToLocal(value, '+0900'))
            }
            if (value && props.saveToHistory) {
              localStorage.setItem('last_datepicker_value', value.toString())
            }
          }}
          style={{
            ...style,
            width: '100%',
          }}
          dateRender={(current) => {
            if (
              current.format('YYYY-MM-DD') ===
              moment().utc().utcOffset('+0900').format('YYYY-MM-DD')
            ) {
              return (
                <div className="ant-picker-cell-inner ant-picker-cell-japan-today">
                  {current.date()}
                </div>
              )
            }
            return <div className="ant-picker-cell-inner">{current.date()}</div>
          }}
          locale={i18n.language === 'ja' ? locale : null}
        />
        {meta.touched && error}
      </React.Fragment>
    )
  }
  if (props.containerStyle) {
    return (
      <div style={props.containerStyle}>
        {/* 
          TECHNICAL DEBT, CSS IN JSX
          Need to override antd date picker today class to display japanese time 'today'
        */}
        <style global jsx>
          {`
            .ant-picker-cell-in-view.ant-picker-cell-today
              .ant-picker-cell-inner::before {
              border: 0px;
              border-radius: 0px;
            }

            .ant-picker-cell-in-view
              .ant-picker-cell-inner.ant-picker-cell-japan-today::before {
              position: absolute;
              top: 0;
              right: 0;
              bottom: 0;
              left: 0;
              z-index: 1;
              border: 1px solid #1890ff;
              border-radius: 2px;
              content: '';
            }
          `}
        </style>
        {renderContent()}
      </div>
    )
  }
  return (
    <React.Fragment>
      {/* 
        TECHNICAL DEBT, CSS IN JSX
        Need to override antd date picker today class to display japanese time 'today'
      */}
      <style global jsx>
        {`
          .ant-picker-cell-in-view.ant-picker-cell-today
            .ant-picker-cell-inner::before {
            border: 0px;
            border-radius: 0px;
          }

          .ant-picker-cell-in-view
            .ant-picker-cell-inner.ant-picker-cell-japan-today::before {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 1;
            border: 1px solid #1890ff;
            border-radius: 2px;
            content: '';
          }
        `}
      </style>
      {renderContent()}
    </React.Fragment>
  )
}

export default connect(FDatePicker)
