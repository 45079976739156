import React from 'react'

import { Space } from 'antd'
import { InputNumberProps } from 'antd/lib/input-number'
import useTitleAndError, {
  UseTitleAndErrorProps,
} from 'fields/useTitleAndError/useTitleAndError'
import type { FormikProps } from 'formik'
import { useField } from 'formik'

import { InputRoundingMethod } from 'components/forms'

export type FInputNumberProps = InputNumberProps &
  UseTitleAndErrorProps & {
    name: string
    roundingMethod: string
    decimalPlace: number
    /**
     * Formik Context
     */
    formik?: FormikProps<any>
  }

function FInputRoundingMethod(props: FInputNumberProps) {
  const [field, meta, helpers] = useField(props as any)

  const [title, error] = useTitleAndError(props)

  return (
    <React.Fragment>
      {title}
      <Space direction="vertical" style={{ width: '100%' }}>
        <InputRoundingMethod
          {...field}
          onChange={(value) => {
            helpers.setValue(value)
          }}
          {...props}
        />
        {meta.touched && error}
      </Space>
    </React.Fragment>
  )
}

export default FInputRoundingMethod
