import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Head from 'next/head'
import { Router, useRouter } from 'next/router'
import Script from 'next/script'

import { Alert } from 'antd'
import Footer from 'layouts/containers/Public/Footer'
import Header from 'layouts/containers/Public/Header'
import MenuBar from 'layouts/containers/Public/MenuBar'

import useUsersSearchRead from 'data/useUsers'

import Loading from 'components/Loading/Loading'
import VerifyingLoading from 'components/Loading/VerifyingLoading'
import PermissionContextProvider from 'contexts/PermissionContext/PermissionContext'
import PosConfigProvider from 'contexts/PosContext/PosContext'
import DateMoment from 'helpers/DateMoment'
import PUBLIC_PAGES from 'utils/constants/page.constant'
import useAccountClosingStore from 'views/InventoryControl/ClosingProcessSetting/stores/accountClosingStore'

interface IProps {
  Component: React.ComponentType<any>
}

/**
 * List of url public pages that not required login to access it
 */
const HEIGHT_HEADER = 56
const HEIGHT_FOOTER = 110

function PublicContainer(props: IProps) {
  const { Component } = props
  const { ready, t } = useTranslation('closing_process_setting')
  const router = useRouter()
  const [isLoading, setLoading] = useState(false)

  const closedDate = useAccountClosingStore((state) => state.closedDate)
  const isClosed = useAccountClosingStore((state) => state.isClosed)

  const BE_URL = process?.env?.BE_URL

  useUsersSearchRead(
    [],
    ['login'],
    1,
    0,
    {},
    { enabled: !PUBLIC_PAGES.includes(router.pathname) },
  )

  useEffect(() => {
    const handleStartLoading = () => {
      setLoading(true)
    }
    const handleStopLoading = () => {
      setLoading(false)
    }

    Router.events.on('routeChangeStart', handleStartLoading)
    Router.events.on('routeChangeComplete', handleStopLoading)
    Router.events.on('routeChangeError', handleStopLoading)

    return () => {
      Router.events.off('routeChangeStart', handleStartLoading)
      Router.events.off('routeChangeComplete', handleStopLoading)
      Router.events.on('routeChangeError', handleStopLoading)
    }
  }, [])

  if (!ready) {
    return <VerifyingLoading />
  }

  if (router.pathname === '/') {
    return <Component {...props} />
  }

  return (
    <>
      <Head>
        <script
          async
          type="text/javascript"
          src="https://cdn.jsdelivr.net/npm/xlsx@0.17.3/dist/xlsx.full.min.js"
        />
      </Head>
      {(BE_URL?.includes('demo') || BE_URL?.includes('staging')) && (
        <Script
          id="ze-snippet"
          src="https://static.zdassets.com/ekr/snippet.js?key=5b409124-4bfa-48cd-955a-d33a15c0fe9a"
        />
      )}
      <Header />
      <MenuBar />
      {isClosed && (
        <Alert
          data-cy="closed_announcement"
          message={t('closing_process_setting:closed_announcement', {
            date: DateMoment.formatDate(closedDate),
          })}
          type="warning"
        />
      )}
      <div
        style={{
          minHeight: `calc(100vh - ${HEIGHT_HEADER + HEIGHT_FOOTER}px)`,
          maxWidth: 2560,
          margin: 'auto',
        }}
      >
        {isLoading && <Loading />}
        <PermissionContextProvider>
          <PosConfigProvider>
            <Component {...props} />
          </PosConfigProvider>
        </PermissionContextProvider>
      </div>
      <Footer />
    </>
  )
}

export default PublicContainer
