const config: PosConfig = {
  link_shipping_detail_id: 'smaregi_link_shipping_detail_id',
  link_arrival_detail_id: 'smaregi_link_arrival_detail_id',

  link_shipping_id: 'smaregi_link_shipping_id',
  link_arrival_id: 'smaregi_link_arrival_id',

  link_pos_shipping_id: 'link_sumaregi_shipping_id',
  link_pos_arrival_id: 'link_sumaregi_arrival_id',

  link_store_id: 'smaregi_link_store_id',
  link_product_id: 'smaregi_product_id',
  pos_store_id: 'sumaregi_store_id',
  link_loss_id: 'smaregi_link_loss_id',

  inventory_quantity: 'smaregi_inventory_quantity',
  layaway_quantity: 'smaregi_layaway_quantity',

  contract_id: 'smaregi_contract_id',
  send_url: 'smaregi_send_url',
}

export default config
