import { useEffect, useState } from 'react'
import { useQuery, UseQueryOptions } from 'react-query'

import { AxiosError } from 'axios'
import { BASE_API_URL } from 'constant'
import ApiCall from 'services/ApiCall'

import parseJWT from 'helpers/parseJWT'
import useUrlQuery, { UseUrlQueryOptions } from 'helpers/QueryUrl/useUrlQuery'

export interface IUsers {
  id: number
  name: string
  login: string
  classification: string
  employee_id: string
  department: string
  department_store: string
  email?: string
  is_admin?: boolean
  job_title: string
  user_id: string
  user_type: 'internal' | 'guest'
  remarks: string
  create_uid: [number, string]
  create_date: Date
  write_uid: [number, string]
  company_id: [number, string]
  write_date: Date
}

type UseUserSearchRead = {
  result: {
    length: number
    records: IUsers[]
  }
}

type TQueryFnData = UseUserSearchRead
type TError = AxiosError

function useUsersSearchRead(
  domain = [],
  fields = [],
  limit?: number | boolean,
  offset?: number | boolean,
  urlOptions?: UseUrlQueryOptions,
  options?: UseQueryOptions<TQueryFnData, TError>,
  sort?: string,
) {
  const urlQuery = useUrlQuery(urlOptions)
  const queryKey = ['GetAllUser', limit, offset, sort, domain, fields]

  const query = useQuery<TQueryFnData, TError>(
    queryKey,
    () =>
      ApiCall.api
        .post(
          urlQuery.transformUrl(
            `${BASE_API_URL}/dataset/res.users/search_read`,
          ),
          {
            params: {
              domain,
              fields: fields || [],
              limit: limit || false,
              offset: offset || false,
              sort,
            },
          },
        )
        .then((res) => {
          return res.data
        }),
    {
      ...options,
    },
  )

  return {
    ...query,
    data: query.data?.result?.records || [],
    records: query.data?.result?.records || [],
    length: query.data?.result?.length || 0,
    helpers: urlQuery,
    queryKey,
  }
}

type DecodeJWT = {
  token_type: string
  aud: string
  exp: number
  iss: string
  jti: string
  user_id: number
}
export const useCurrentUser = () => {
  const [userId, setUserId] = useState<null | number>()

  useEffect(() => {
    const access_token = localStorage.getItem('token-ldx')
    const parsedJwt: DecodeJWT = parseJWT(access_token)

    setUserId(parsedJwt?.user_id || 0)
  }, [])

  return userId
}
export default useUsersSearchRead
