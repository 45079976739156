import { round } from 'lodash'
import React from 'react'
import { Resizable } from 'react-resizable'

type ResizeableCellProps = {
  onResize: () => void
  width: string | number
  onClick: (e: Record<string, unknown>) => void
  fixed?: boolean
}

const ResizeableCell: React.FC<ResizeableCellProps> = (props) => {
  const { onResize, width, onClick, fixed: _fixed, ...restProps } = props

  if (!width) {
    return <th {...restProps} />
  }

  const handleClick = (e) => {
    if (
      e.target.className !==
        'react-resizable-handle react-resizable-handle-se' &&
      onClick &&
      !(e.target instanceof SVGElement)
    ) {
      onClick(e)
    }
  }

  return (
    <Resizable
      width={round(Number(width))}
      height={0}
      onResize={onResize}
      onClick={handleClick}
      handle={
        <span
          className="react-resizable-handle"
          role="presentation"
          onClick={(e) => {
            e.stopPropagation()
          }}
        />
      }
      draggableOpts={{ enableUserSelectHack: false }}
    >
      <th {...restProps} />
    </Resizable>
  )
}

export default React.memo(ResizeableCell)
