import React from 'react'

import { useRouter } from 'next/router'

import Layout from 'layouts/containers/Public'

import { queryClient as ProviderQueryClient } from 'components/Provider'
import PUBLIC_PAGES from 'utils/constants/page.constant'

/**
 * @deprecated
 * use queryClient from src/components/Provider/Provider.tsx instead
 */
export const queryClient = ProviderQueryClient

function getSiteLayout(appProps) {
  const router = useRouter()

  if (PUBLIC_PAGES.includes(router.pathname)) {
    const { Component, ...props } = appProps
    return <Component {...props} />
  }

  return <Layout {...appProps} />
}

export default getSiteLayout
