import React, { useEffect } from 'react'

import Head from 'next/head'
import { useRouter } from 'next/router'

import { enableReactTracking } from '@legendapp/state/config/enableReactTracking'
import getSiteLayout from 'layouts/core/DefaultLayout'

import useMenu from 'data/useMenu'

import usePreventEnter from 'hooks/usePreventEnter'

import Provider from 'components/Provider'
import titleHeader from 'helpers/titleHeader'

import 'moment/locale/ja'

import 'react-table-6/react-table.css'
import '@agungkes/react-table-hoc-draggable-columns/dist/index.css'
import 'react-table-hoc-fixed-columns/lib/styles.css'
import 'views/MDExecution/PriceSetting/PriceChangeSetting/Markdown/MarkdownSetting.css'
import '../styles/global.css'

const title = 'LDX'
const description = 'LDX App'
const metaURL = 'https://github.com'
const metaImage = '/static/logo.png'
const webIconURL = '/static/logo.png'

if (process.env.NEXT_PUBLIC_API_MOCKING === 'enabled') {
  require('../../mocks')
}

enableReactTracking({
  auto: true,
})

const MyApp = (props) => {
  const siteLayout = getSiteLayout(props)
  const router = useRouter()
  const { t, ready } = useMenu()
  const menus = ready ? (t('menus', { returnObjects: true }) as any) : []
  const titleMenu = titleHeader(t, router.pathname, menus)
  const dynamicTitle = titleMenu ? `- ${titleMenu}` : ''

  usePreventEnter()

  let time = null
  useEffect(() => {
    try {
      window.addEventListener('click', (event) => {
        const { type, value, innerText, localName }: any = event.target
        // check input type text and not empty
        if (
          (['text', 'textarea'].includes(type) && value !== '') ||
          (['div'].includes(localName) && ['0'].includes(innerText))
        ) {
          clearTimeout(time)
          time = setTimeout(() => {
            const active: any = document.activeElement
            if (active.tagName === 'INPUT') {
              // select / block all text
              active?.select()
            }
          }, 100)
        }
      })
    } catch (error) {
      console.log(error)
    }
  }, [])

  return (
    <React.Fragment>
      <Head>
        <title>{`${title} ${dynamicTitle}`}</title>
        <meta name="robots" content="noindex" />
        <meta name="title" content={title} />
        <link rel="shortcut icon" href={webIconURL} />
        <meta name="description" content={description} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={metaURL} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={metaImage} />
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content={metaURL} />
        <meta property="twitter:title" content={title} />
        <meta property="twitter:description" content={description} />
        <meta property="twitter:image" content={metaImage} />
      </Head>
      <Provider>{siteLayout}</Provider>
    </React.Fragment>
  )
}
export default MyApp
