import React from 'react'
import { useTranslation } from 'react-i18next'

import { Input, Tooltip } from 'antd'
import { TextAreaProps } from 'antd/lib/input'

const { TextArea: AntTextArea } = Input
type Props = TextAreaProps & TooltipInputProps
const TextArea: React.FC<Props> = ({
  errorMessage,
  showErrorMessage,
  ...props
}): JSX.Element => {
  const { t } = useTranslation()

  return (
    <Tooltip
      title={errorMessage}
      visible={showErrorMessage}
      color="red"
      destroyTooltipOnHide
    >
      <AntTextArea placeholder={t('input')} {...props} />
    </Tooltip>
  )
}

export default TextArea
