import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { get, round } from 'lodash'

import { useGetCurrentLang } from 'data/Language'

import { ColumDef, ColumnType, UseColumnProps } from '../types'
import { getColumnTitleWidth, getTextAlignment, getTitleName } from '../utils'

function useColumnsV2(
  dict:
    | string
    | {
        dict: string
        jsonKey?: string
        selectionWidth?: number
        loadDict?: string[]
      },
  /**
   * React i18n TFunction key.
   *
   * To get specific columns by json key default is `"headers"`
   */
  jsonKey?: string,
  selectionWidth?: number,
) {
  const config = {
    dict:
      typeof dict === 'string' ? dict : [dict.dict, ...(dict.loadDict ?? [])],
    jsonKey: typeof dict === 'string' ? jsonKey : dict.jsonKey,
    selectionWidth: typeof dict === 'string' ? selectionWidth : dict.jsonKey,
  }

  const { t, i18n, ready } = useTranslation(config.dict)
  const { data: currentLang } = useGetCurrentLang()

  const names = ready
    ? (t(config.jsonKey || 'headers', { returnObjects: true }) as ColumDef[])
    : []

  const [columns, setColumns] = useState<ColumnType<any>[]>([])

  const transformColumns = (x: UseColumnProps, i: number) => {
    const arrayAccessor = (() => {
      if (Array.isArray(x.accessor)) {
        if (x.accessor[0].includes('_id') || x.accessor[0].includes('_uid')) {
          return x.accessor[0]
        }

        return x.accessor[1]
      }

      return x.accessor
    })()

    const accessor = arrayAccessor.includes('_id')
      ? arrayAccessor.slice(0, -3)
      : arrayAccessor

    const title = getTitleName(config.dict, accessor, x.name)
    const hidden = x.hidden || false
    const { format } = x

    let width = x?.width || getColumnTitleWidth(title, !!x?.fixed)
    width = round(Number(width), 0) * (currentLang === 'en_US' ? 1.05 : 1.15)

    const disabledSorter =
      arrayAccessor.includes('_id') &&
      x.accessor !== 'remove_row' &&
      x.accessor !== 'copy_row'
    const column: ColumnType<any> = {
      key: accessor,
      dataIndex: x.accessor,
      title,
      headerName: title,
      width,
      ellipsis: true,
      hidden,
      fixed: x.fixed,
      editable: get(x, 'editable', false),
      format,
      children: x.headers
        ? x?.headers?.map(transformColumns)
        : x?.children?.map(transformColumns),
      type: x?.type,
      align: x?.align || getTextAlignment(x.format),
      delete: get(x, 'delete', true),
      allowClear: get(x, 'allowClear', false),
      required: get(x, 'required', false),
      sorter:
        (!disabledSorter && {
          multiple: i + 1,
        }) ||
        false,
    }

    return column
  }

  useEffect(() => {
    if (ready) {
      setColumns([
        {
          key: 'select_action',
          dataIndex: 'id',
          title: ' ',
          width: config.selectionWidth || 100,
          headerName: ' ',
          ellipsis: false,
          hidden: false,
          editable: false,
          delete: false,
          type: 'select',
          fixed: 'left',
        },
        ...names.map(transformColumns),
      ])
    }
  }, [i18n.language, ready])

  return { columns, lang: i18n.language }
}

export default useColumnsV2
