import React from 'react'

import { AutoComplete } from 'antd'
import { AutoCompleteProps } from 'antd/lib/auto-complete'
import useTitleAndError, {
  UseTitleAndErrorProps,
} from 'fields/useTitleAndError/useTitleAndError'
import { useField } from 'formik'

type FAutoCompleteProps = AutoCompleteProps & UseTitleAndErrorProps

function FSelect(props: FAutoCompleteProps) {
  const [field, , helpers] = useField(props as any)
  const [title, error] = useTitleAndError(props)

  return (
    <React.Fragment>
      {title}
      <AutoComplete
        {...field}
        onBlur={() => {
          helpers.setTouched(true)
        }}
        onChange={(value) => {
          helpers.setValue(value)
        }}
        onSelect={(value) => helpers.setValue(value)}
        {...props}
      />
      {error}
    </React.Fragment>
  )
}

export default FSelect
