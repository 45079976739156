import { useLocalStorage } from 'beautiful-react-hooks'

import useContract from 'data/useContract'

const envList = [
  ...['', 2, 3, 4, 5, 6, 7, 8, 9, 10].map((k) => `dev${k}.l-dx.net`),
  'dev-indonesia-1.l-dx.net',
  'dev-indonesia-2.l-dx.net',
  'dev-indonesia-3.l-dx.net',
  'dev-md.l-dx.net',
  'preview.l-dx.net',
  'preview2.l-dx.net',
  'demo.l-dx.net',
  'demo2.l-dx.net',
  'localhost',
  ...['tsg'].map((k) => `${k}.staging.l-dx.net`),
]

export default function DevEnvironmentInfo() {
  // Request to save in local storage from T.Kasano (because company name is rarely changed)
  const [company_name, setCompanyName] = useLocalStorage(
    'company_name',
    undefined,
  )
  useContract(undefined, {
    onSuccess(resp) {
      setCompanyName(resp.result?.records?.[0]?.name)
    },
    enabled: !company_name,
  })
  if (!envList.includes(window.location.hostname)) {
    return null
  }
  return (
    <span id="server-env-information">
      TEST ENV{company_name ? ` (${company_name})` : ''}
    </span>
  )
}
