// const mapApiUrl = {
//   local: 'http://localhost:3333/odoo-local',
//   localhost: 'http://localhost:3333/odoo-dev',
//   localhost2: 'http://localhost:3333/odoo-dev2',
//   localhost3: 'http://localhost:3333/odoo-dev3',
//   localhost4: 'http://localhost:3333/odoo-dev4',
//   localhost5: 'http://localhost:3333/odoo-dev5',
//   staging: 'https://staging.l-dx.net/api',
//   development: 'https://api-dev.l-dx.net/api',
//   development2: 'https://api-dev2.l-dx.net/api',
//   development3: 'https://api-dev3.l-dx.net/api',
//   development4: 'https://api-dev4.l-dx.net/api',
//   development5: 'https://api-dev5.l-dx.net/api',
//   demo: 'https://api-demo.l-dx.net/api',
//   demo_n9: 'https://demo-n9.l-dx.net/api',
//   erp1: 'https://api.erp1.l-dx.jp/api',
//   erp2: 'https://api.erp2.l-dx.jp/api',
//   erp3: 'https://api.erp3.l-dx.jp/api',
// }

// const mapUrl = {
//   local: 'http://127.0.0.1:8069/api',
//   localhost: 'https://api-dev.l-dx.net/',
//   localhost2: 'https://api-dev2.l-dx.net/',
//   localhost3: 'https://api-dev3.l-dx.net/',
//   localhost4: 'https://api-dev4.l-dx.net/',
//   localhost5: 'https://api-dev5.l-dx.net/',
//   staging: 'https://staging.l-dx.net/',
//   development: 'https://api-dev.l-dx.net/',
//   development2: 'https://api-dev2.l-dx.net/',
//   development3: 'https://api-dev3.l-dx.net/',
//   development4: 'https://api-dev4.l-dx.net/',
//   development5: 'https://api-dev5.l-dx.net/',
//   demo: 'https://api-demo.l-dx.net/',
//   demo_n9: 'https://demo-n9.l-dx.net/',
//   erp1: 'https://api.erp1.l-dx.jp/',
//   erp2: 'https://api.erp2.l-dx.jp/',
//   erp3: 'https://api.erp3.l-dx.jp/',
// }

module.exports = {
  BASE_API_URL: (process.env.BE_URL || '').includes('l-dx')
    ? `${process.env.BE_URL}/api`
    : process.env.BE_URL,
  BASE_IMAGE_URL: `${process.env.IMAGE_URL || process.env.BE_URL}`,
}
