import moment from 'moment'

import DateMoment from 'helpers/DateMoment'
import { FORMAT_BACKEND_DATE } from 'utils/constants'

const getAccessTimeFromDomain = (
  domain = [],
  transactionDayKey = 'invoice_date',
) => {
  const isDomainHasTransactionDay = domain?.some(
    (s) => s[0] === transactionDayKey,
  )
  const invoiceDate = domain?.filter((s) => s[0] === transactionDayKey)
  const invoiceStartDate = moment(invoiceDate?.[0]?.[2]).format(
    FORMAT_BACKEND_DATE,
  )
  const invoiceEndDate = moment(invoiceDate?.[1]?.[2]).format(
    FORMAT_BACKEND_DATE,
  )

  return {
    accessTime:
      (!isDomainHasTransactionDay && DateMoment.convertToDateUtc(moment())) ||
      undefined,
    startDate: (isDomainHasTransactionDay && invoiceStartDate) || undefined,
    endDate: (isDomainHasTransactionDay && invoiceEndDate) || undefined,
  }
}

export default getAccessTimeFromDomain
