import { useTranslation } from 'react-i18next'

const MENU_ENV = process.env.MENU_ENV || 'menu'

type IParams = {
  ns?: string[]
}

function useMenu(params: IParams = {}) {
  const { ns = [] } = params
  const menu = useTranslation([
    MENU_ENV,
    // Prevent duplicate i18n
    ...ns.filter((i) => i !== MENU_ENV),
  ])

  return menu
}

export default useMenu
