import React from 'react'
import { useTranslation } from 'react-i18next'

import type { InputNumberProps } from 'antd'
import { InputNumber as AntInputNumber, Tooltip } from 'antd'

import { formatNumber, formatRoundingMethod } from 'utils/helpers/formatter'

type Props = InputNumberProps &
  TooltipInputProps & {
    roundingMethod: string
    decimalPlace: number
  }
const InputRoundingMethod: React.FC<Props> = ({
  errorMessage,
  showErrorMessage,
  className,
  roundingMethod,
  decimalPlace,
  ...props
}): JSX.Element => {
  const { t } = useTranslation()

  return (
    <Tooltip
      title={errorMessage}
      visible={showErrorMessage}
      color="red"
      destroyTooltipOnHide
    >
      <AntInputNumber
        formatter={(value) =>
          formatNumber(
            formatRoundingMethod(value, roundingMethod, decimalPlace),
          )
        }
        parser={(value) =>
          formatRoundingMethod(value, roundingMethod, decimalPlace)
        }
        placeholder={t('common:input_number')}
        className={`w-full ${className}`}
        {...props}
      />
    </Tooltip>
  )
}

export default InputRoundingMethod
