import { useMutation } from 'react-query'

import { languageService } from 'services'

type Props = {
  id: number
  data: {
    value: string
  }
}
const useUpdateTranslatableFieldMutation = () => {
  return useMutation(({ id, data }: Props) =>
    languageService.updateTranslate(id, data),
  )
}

export default useUpdateTranslatableFieldMutation
