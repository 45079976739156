import { useTranslation } from 'react-i18next'

import i18n from 'layouts/i18n'
import { MAP_LANGUAGE } from 'services/language.service'

const useGetCurrentLang = () => {
  const { i18n } = useTranslation()
  return {
    data: MAP_LANGUAGE[i18n.language],
  }
}

export const getCurrentLang = () => MAP_LANGUAGE[i18n?.language]
export default useGetCurrentLang
