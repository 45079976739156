import { useQuery } from 'react-query'

import { camelCase } from 'lodash'
import ApiCall from 'services/ApiCall'

type SnakeToCamelCase<S extends string> = S extends `${infer T}_${infer U}`
  ? `${T}${Capitalize<SnakeToCamelCase<U>>}`
  : S
type ReturnType<T extends string> = Record<SnakeToCamelCase<T>, string>

type UseInstalledModuleResult = {
  result: {
    records: any[]
    length: number
  }
}

const useCheckInstalledModule = <T extends string>(moduleName: T[]) => {
  const query = useQuery<UseInstalledModuleResult>(
    ['checkInstalledModule'],
    async () => {
      const res = await ApiCall.api.post(
        '/dataset/ir.module.module/search_read/',
        {
          params: {
            domain: [['name', 'in', moduleName]],
            fields: ['state', 'name'],
            limit: moduleName.length,
          },
        },
      )

      return res.data
    },
  )

  const initialState = moduleName.reduce(
    (acc, module) => ({
      ...acc,
      [camelCase(module)]: false,
    }),
    {},
  )

  return {
    ...query,
    data: (query?.data?.result?.records?.reduce((acc, curr) => {
      acc[camelCase(curr.name)] = curr.state === 'installed'
      return acc
    }, {}) ?? initialState) as ReturnType<T>,
  }
}

export default useCheckInstalledModule
