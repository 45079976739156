import React from 'react'
import { useTranslation } from 'react-i18next'

import dynamic from 'next/dynamic'

import type { InputProps } from 'antd'
import { Input as AntInput } from 'antd'
import { TextAreaProps } from 'antd/lib/input'

import { DataSubmitProps } from 'components/forms/ModalMultilanguage/ModalMultilanguage'

const ModalMultilanguage = dynamic(() => import('../ModalMultilanguage'))
type Props = (TextAreaProps | InputProps) &
  TooltipInputProps & {
    /**
     * Multilanguage support
     */
    isTextArea?: boolean
    multiLanguage?: {
      title: string
      model: string
      modelId?: string | number
      onSubmit: (values: DataSubmitProps[]) => void
      field?: string
      multiLanguageValues?: any
    }
  }
const { TextArea } = AntInput

const Input: React.FC<Props> = ({
  multiLanguage,
  isTextArea,
  errorMessage: _e,
  showErrorMessage: _sm,
  ...props
}): JSX.Element => {
  const { t } = useTranslation()
  return (
    <AntInput.Group compact className="flex items-center">
      {isTextArea ? (
        <TextArea
          placeholder={t('input')}
          allowClear
          {...(props as TextAreaProps)}
          style={
            multiLanguage && { ...props.style, width: 'calc(100% - 50px)' }
          }
        />
      ) : (
        <AntInput
          placeholder={t('input')}
          allowClear
          {...(props as InputProps)}
          style={
            multiLanguage && { ...props.style, width: 'calc(100% - 50px)' }
          }
        />
      )}

      {multiLanguage && (
        <ModalMultilanguage
          name={props.name}
          data-cy={`modal-multi-${props.name}`}
          initialValue={props.value}
          isTextArea={isTextArea}
          {...multiLanguage}
        />
      )}
    </AntInput.Group>
  )
}

export default Input
