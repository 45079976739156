import React from 'react'

import Text from 'components/Typography/Text'

export type ExtendedLabelProps = {
  title: string
  requiredMark?: boolean
  textColor?: string
  className?: string
  /**
   * Only work if `requiredMark` prop is `true`
   */
  classNameContainer?: string
}

export function ExtendedLabel({
  title,
  requiredMark = false,
  textColor = 'white',
  className,
  classNameContainer,
}: ExtendedLabelProps) {
  return requiredMark ? (
    <div className={classNameContainer}>
      <Text
        color={textColor}
        // style={{ whiteSpace: 'nowrap' }}
        style={{ whiteSpace: 'inherit' }}
        className={className}
      >
        {title}
      </Text>
      <Text color="red" style={{ marginLeft: '4px' }} size={16}>
        *
      </Text>
    </div>
  ) : (
    <Text
      color={textColor}
      // style={{ whiteSpace: 'nowrap' }}
      style={{ whiteSpace: 'inherit' }}
      className={className}
    >
      {title}
    </Text>
  )
}
