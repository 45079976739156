import { z } from 'zod'

/* c8 ignore start */
export const validateDataSchema = <T extends z.ZodType>(
  schema: T,
  data: z.infer<T>,
) => {
  try {
    return schema.parse(data) as typeof data
  } catch (err) {
    console.error(err)
    console.table(data)
    throw new Error('Data validation zod schema failed')
  }
}
export const validateAsyncDataSchema = async <T extends z.ZodType>(
  schema: T,
  data: z.infer<T>,
) => {
  const result = await schema.safeParseAsync(data)
  return {
    success: result.success,
    data: result.data,
    errorIssues: result.error?.issues || [],
  }
}
/* c8 ignore stop */
