import React from 'react'
import { useTranslation } from 'react-i18next'

import type { SelectProps } from 'antd'
import { Select as AntSelect } from 'antd'

import { filterOption } from 'helpers/Utils'

type Props = SelectProps & TooltipInputProps
const Select: React.FC<Props> = ({
  errorMessage: _errorMessage,
  showErrorMessage: _showErrorMessage,
  ...props
}): JSX.Element => {
  const { t } = useTranslation()

  return (
    <AntSelect
      placeholder={t('common:select')}
      className="min-w-[120px]"
      allowClear
      showSearch
      filterOption={filterOption}
      {...props}
    />
  )
}

export default Select
