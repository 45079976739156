import React from 'react'
import { Radio, RadioGroupProps } from 'antd'
import { useField } from 'formik'
import useTitleAndError, {
  UseTitleAndErrorProps,
} from 'fields/useTitleAndError/useTitleAndError'

type NumberProps = RadioGroupProps &
  UseTitleAndErrorProps & {
    isNegation?: boolean
  }
const FRadio = (props: NumberProps) => {
  const [field] = useField(props as any)
  const [title, error] = useTitleAndError(props)
  return (
    <React.Fragment>
      {title}
      <Radio.Group {...field} {...props} />
      {error}
    </React.Fragment>
  )
}

export default FRadio
