import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { Col, Divider, Row, Select } from 'antd'
import { SelectProps, SelectValue } from 'antd/lib/select'
import { usePreviousValue } from 'beautiful-react-hooks'
import useTitleAndError, {
  UseTitleAndErrorProps,
} from 'fields/useTitleAndError/useTitleAndError'
import { useField } from 'formik'

import { filterOption } from 'helpers/Utils'

import cssFSelect from './FSelect.module.scss'

export type FSelectProps<VT extends SelectValue = SelectValue> =
  SelectProps<VT> &
    UseTitleAndErrorProps & {
      inline?: boolean
      onAdd?: string
      validateOnChange?: boolean
    }

function FSelect(props: FSelectProps) {
  const { t } = useTranslation('common')
  const [field, meta, helpers] = useField(props as any)
  const [title, error] = useTitleAndError(props)
  const { inline, onAdd, validateOnChange, onChange, ...restProps } = props
  const prevTouched = usePreviousValue(meta.touched)

  // Fix issue touched is back to false/undefined
  useEffect(() => {
    if (prevTouched === true && !field.value) {
      helpers.setTouched(true)
    }
  }, [meta.touched])

  if (inline) {
    return (
      <Row align="middle" gutter={10}>
        {title}
        <Col xs={13}>
          <Select
            {...field}
            id={props.id || props.name}
            onBlur={() => {
              helpers.setTouched(true)
            }}
            onClear={() => {
              helpers.setTouched(true)
            }}
            onChange={(value = null, options) => {
              helpers.setValue(value, validateOnChange)
              if (onChange) {
                onChange(value, options)
              }
            }}
            dropdownRender={
              !!onAdd &&
              ((menu) => (
                <React.Fragment>
                  {menu}
                  <Divider style={{ margin: '1rem' }} />
                  <a
                    href={onAdd}
                    target="_blank"
                    className="ant-btn ant-btn-primary"
                    rel="noreferrer"
                  >
                    {t('common:addItem')}
                  </a>
                </React.Fragment>
              ))
            }
            placeholder={t('common:select')}
            {...restProps}
          />
        </Col>
        {meta.touched && error}
      </Row>
    )
  }
  return (
    <React.Fragment>
      <div id={cssFSelect.placeholderSize}>
        {title}
        <Select
          data-cy={props.name}
          allowClear
          showSearch
          filterOption={filterOption}
          placeholder={t('common:select')}
          {...field}
          id={props.id || props.name}
          onBlur={() => {
            helpers.setTouched(true)
          }}
          onClear={() => {
            helpers.setTouched(true)
          }}
          onChange={(value = null, options) => {
            helpers.setValue(value)
            if (onChange) {
              onChange(value, options)
            }
          }}
          dropdownRender={
            !!onAdd &&
            ((menu) => (
              <React.Fragment>
                {menu}
                <Divider style={{ margin: 4 }} />
                <div style={{ paddingLeft: 8, paddingRight: 8 }}>
                  <a
                    href={onAdd}
                    className="ant-btn ant-btn-primary ant-btn-block"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {t('common:addItem')}
                  </a>
                </div>
              </React.Fragment>
            ))
          }
          {...restProps}
        />
        {meta.touched && error}
      </div>
    </React.Fragment>
  )
}

export default FSelect
