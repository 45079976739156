import { toUpper } from 'lodash'

type GenerateApiReturn<T extends string> = Record<Uppercase<T>, string>

const defaultActions = [
  'search_read',
  'read',
  'create',
  'write',
  'unlink',
  'read_group',
] as const
type A = typeof defaultActions[number]

function generateApiRoutes<T extends string>(model: string, actions: T[] = []) {
  return [...actions, ...defaultActions].reduce(
    (acc, action) => ({
      ...acc,
      [toUpper(action)]: `/dataset/${model}/${action}/`,
    }),
    {},
  ) as GenerateApiReturn<A> & GenerateApiReturn<T>
}

export default generateApiRoutes
