import { useEffect } from 'react'

import { useRouter } from 'next/router'

import PUBLIC_PAGES from 'utils/constants/page.constant'

/**
 * Prevent enter key press submit form
 */
const usePreventEnter = () => {
  const router = useRouter()
  useEffect(() => {
    if (!PUBLIC_PAGES.includes(router.pathname)) {
      const handleKeyPress = (e: KeyboardEvent) => {
        if (e.key === 'Enter') {
          if ((e.target as Element).tagName !== 'TEXTAREA') {
            e.preventDefault()
            return false
          }
        }
      }
      document.addEventListener('keydown', handleKeyPress)

      return () => {
        document.removeEventListener('keydown', handleKeyPress)
      }
    }
  }, [router.pathname])
}

export default usePreventEnter
