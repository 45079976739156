/**
 * Used to parse the data from the InputNumber component
 *
 * @param value
 * @returns
 */
const parseInputPercentage = (value: string) => value.replace('%', '')

/**
 * Used to remove the currency symbol from the value
 *
 * @param value
 * @returns
 */
const inputParserNumber = (value) => value.replace(/\$\s?|(,*)/g, '')

export { inputParserNumber, parseInputPercentage }
