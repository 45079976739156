import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Input as AntInput, InputNumber as AntInputNumber } from 'antd'
import { max } from 'lodash'

import useContract from 'data/useContract'
import useCurrency from 'data/useCurrency'

import { Input as MInput } from 'components/forms'
import { JAPAN_CURRENCY_ID } from 'utils/constants'

import { decimalAdjustment, displayFormater } from './services/formatter'

export function Input(
  props: Parameters<typeof AntInput>[0] & { displayTextWhenDisabled?: boolean },
) {
  const { t } = useTranslation(['common'])
  const { displayTextWhenDisabled, ...restProps } = props || {}
  return (
    <AntInput
      allowClear
      {...restProps}
      placeholder={restProps?.placeholder ?? t('common:input')}
      bordered={
        displayTextWhenDisabled && restProps.disabled
          ? false
          : restProps.bordered
      }
    />
  )
}
Input.TextArea = function InputTextArea(
  props: Parameters<typeof AntInput.TextArea>[0],
) {
  const { t } = useTranslation(['common'])
  return (
    <AntInput.TextArea
      {...props}
      rows={props.rows ?? 3}
      placeholder={props?.placeholder ?? t('common:input')}
    />
  )
}
type CurrencyHooksProps = {
  roundingMethod?: 'round_up' | 'round_down' | 'rounding'
  roundingMethodUsage?: 'purchase' | 'sales' | 'default'
  currencyId?: number
  useRounding?: boolean
  decimalPlaces?: number
  defaultNaNValue?: string | number
  decimal?: boolean
  minimumFractionDigits?: boolean | number
  useContractDecimalPlaces?: boolean
}
// to cache the searchContract
type SearchContractMasterParams = Parameters<typeof useContract>
function useSearchContractMaster(options?: SearchContractMasterParams[1]) {
  return useContract(
    {
      fields: [
        'rounding_method',
        'rounding_method_purchase',
        'rounding_method_sales',
        'ratio_decimal_places',
        'qty_decimal_places',
        'currency_decimal_places',
      ],
    },
    options,
  )
}

export function useCurrencyHooks(props?: CurrencyHooksProps) {
  const {
    decimal = true,
    currencyId = JAPAN_CURRENCY_ID,
    decimalPlaces,
    defaultNaNValue,
    roundingMethod,
    useRounding = true,
    roundingMethodUsage = 'default',
    useContractDecimalPlaces,
    minimumFractionDigits: _minimumFractionDigits,
  } = props || {}

  const searchCurrency = useCurrency(
    {
      field: ['decimal_places', 'display_decimal_digits'],
      domain: [['id', '=', currencyId]],
    },
    {
      enabled: !!currencyId && decimal && !decimalPlaces,
      refetchOnMount: false,
    },
  )
  const currencyData = searchCurrency.data?.[0]
  const searchContract = useSearchContractMaster({
    enabled: (!roundingMethod || useContractDecimalPlaces) && useRounding,
    refetchOnMount: false,
  })
  const isFetching = searchCurrency.isFetching || searchContract.isFetching
  const contract = searchContract.data
  function getRoundingContract() {
    if (roundingMethodUsage === 'sales') {
      return contract?.rounding_method_sales
    }
    if (roundingMethodUsage === 'purchase') {
      return contract?.rounding_method_purchase
    }
    return contract?.rounding_method
  }
  function getMinimumFractionDigits() {
    if (typeof _minimumFractionDigits === 'boolean') {
      return _minimumFractionDigits ? decimal_places : 0
    }
    return _minimumFractionDigits
  }

  const decimal_places = useContractDecimalPlaces
    ? contract?.currency_decimal_places
    : decimalPlaces ?? currencyData?.decimal_places ?? 2
  const minimumFractionDigits = getMinimumFractionDigits()

  const rounding_method = useRounding
    ? roundingMethod ?? getRoundingContract() ?? 'rounding'
    : undefined
  const stringFormat = displayFormater.number.inputFn.WithOptions({
    maximumFractionDigits: max([decimal_places, minimumFractionDigits]),
    // always show decimal digits {x}.00
    minimumFractionDigits: max([
      minimumFractionDigits,
      currencyData?.display_decimal_digits || 0,
    ]),
    roundingMethod: rounding_method as any,
  })
  function parse(val: string) {
    if (!val) return defaultNaNValue || 0
    return displayFormater.number.parseNumber(val, {
      maximumFractionDigits: decimal_places,
      roundingMethod: rounding_method as any,
    })
  }
  return {
    decimalAdjustment,
    config: {
      decimal_places,
      rounding_method,
    },
    numberFormatter: displayFormater.number,
    stringFormat,
    parse,
    formatStringThenParse(val: any) {
      return Number(parse(stringFormat(val)) || 0)
    },
    isLoading: isFetching,
  }
}
useCurrencyHooks.useSearchContractMaster = useSearchContractMaster

type InputNumberProps = Parameters<typeof AntInputNumber>[0] & {
  textAlign?: 'left' | 'right'
  decimalPlaces?: number
  defaultNaNValue?: string | number
  decimal?: boolean
  displayTextWhenDisabled?: boolean
  asText?: boolean
  /*
    add style color:red and negative pseudoelement when the value is negative (only works when asText is true)
   */
  markNegativeValue?: boolean

  // controls?: never;
  /** handle if value infinite, use fallback  */
  infinityValueFallback?: string | number
}

/**
 *
 * @param props minimumFractionDigits => use this to always show decimals ex: 2.00
 * @returns
 */
function InputNumber(
  props: InputNumberProps & { minimumFractionDigits?: boolean | number },
) {
  const { t } = useTranslation(['common'])
  const {
    controls = false,
    decimal = true,
    decimalPlaces = 0,
    minimumFractionDigits: _minimumFractionDigits = false,
    defaultNaNValue,
    textAlign,
    displayTextWhenDisabled,
    asText = false,
    markNegativeValue = false,
    infinityValueFallback,
    ...restProps
  } = props || {}
  const [{ focused, value: localValue }, setState] = useState({
    focused: false,
    value: restProps.value,
  })
  const minimumFractionDigits = useMemo(() => {
    if (typeof _minimumFractionDigits === 'boolean') {
      return _minimumFractionDigits ? decimalPlaces : 0
    }
    return _minimumFractionDigits
  }, [_minimumFractionDigits])

  const setFocused = (v: boolean) =>
    setState((prev) => ({ ...prev, focused: v }))
  const setValue = (v: any) => setState((prev) => ({ ...prev, value: v }))

  const displayString = displayFormater.number.inputFn.WithOptions({
    maximumFractionDigits: max([decimalPlaces, minimumFractionDigits]),
    minimumFractionDigits: focused ? 0 : minimumFractionDigits,
  })

  const classNames = [
    props.className,
    `number-align-${textAlign || 'left'}`,
    controls && !props.disabled && !props.readOnly ? 'number-has-control' : '',
    displayTextWhenDisabled ? 'display-text' : '',
  ]
    .filter((x) => !!x)
    .join(' ')
  if (asText) {
    const isNegative = ((Number(props.value) || 0) as number) < 0
    let valueAsText = decimal
      ? displayString(
          (markNegativeValue && isNegative ? -1 : 1) *
            ((props.value || 0) as number),
        )
      : undefined

    if (typeof props.value === 'number' && !isFinite(props.value)) {
      valueAsText = infinityValueFallback
        ? String(infinityValueFallback)
        : valueAsText
    }
    const textClassName = [
      'input-number-as-text',
      `number-align-${textAlign || 'left'}`,
      textAlign === 'right' ? `block text-right` : null,
      markNegativeValue && ((Number(props.value) || 0) as number) < 0
        ? 'negative-amount'
        : null,
    ]
      .filter((x) => !!x)
      .join(' ')
    return (
      <span className={textClassName}>
        {props.addonBefore ? (
          <span className="addon-before">{props.addonBefore}</span>
        ) : undefined}
        {typeof props.value !== 'number' ? defaultNaNValue ?? 0 : valueAsText}
        {props.addonAfter ? (
          <span className="addon-after">{props.addonAfter}</span>
        ) : undefined}
      </span>
    )
  }

  if (!focused && minimumFractionDigits) {
    return (
      <AntInputNumber
        key={'ant-not-focused-input-number'}
        placeholder={props.disabled ? '' : (t('input_number') as string)}
        formatter={
          decimal
            ? (val: any) => (val === '-' || !val ? val : displayString(val))
            : undefined
        }
        parser={
          decimal
            ? (val) => {
                if (!val) return defaultNaNValue || 0
                return displayFormater.number.parseNumber(val, {
                  maximumFractionDigits: decimalPlaces,
                })
              }
            : undefined
        }
        {...restProps}
        value={restProps?.value ?? localValue}
        controls={controls}
        className={classNames}
        onFocus={(e) => {
          setFocused(true)
          restProps.onFocus?.(e)
        }}
      />
    )
  }

  return (
    <AntInputNumber
      autoFocus={!!minimumFractionDigits}
      placeholder={props.disabled ? '' : (t('input_number') as string)}
      formatter={
        decimal
          ? (val: any) => (val === '-' || !val ? val : displayString(val))
          : undefined
      }
      parser={
        decimal
          ? (val) => {
              if (!val) return defaultNaNValue || 0
              return displayFormater.number.parseNumber(val, {
                maximumFractionDigits: decimalPlaces,
              })
            }
          : undefined
      }
      {...restProps}
      value={restProps?.value ?? localValue}
      onChange={(...args) => {
        setValue(args[0])
        restProps.onChange?.(...args)
      }}
      controls={controls}
      className={classNames}
      onBlur={(e) => {
        if (minimumFractionDigits) {
          setFocused(false)
        }
        restProps.onBlur?.(e)
      }}
    />
  )
}

InputNumber.Quantity = function InputNumberQuantity(
  props: Parameters<typeof InputNumber>[0],
) {
  const searchContract = useSearchContractMaster({
    enabled:
      (props?.decimal ?? true) && typeof props?.decimalPlaces !== 'number',
    refetchOnMount: false,
  })
  return (
    <InputNumber
      decimalPlaces={searchContract?.data?.qty_decimal_places ?? 2}
      {...props}
    />
  )
}

InputNumber.ExchangeRate = function InputNumberExchangeRate(
  props: Parameters<typeof InputNumber>[0],
) {
  return <InputNumber decimalPlaces={16} min={0} {...props} />
}
InputNumber.Percentage = function InputNumberRatioInPercentage({
  transformToRatio,
  ...props
}: Parameters<typeof InputNumber>[0] & { transformToRatio?: boolean }) {
  const newProps = transformToRatio
    ? ({
        value: decimalAdjustment(Number(props.value) || 0)
          .times(100)
          .toNumber(),
        onChange(val) {
          props.onChange?.(
            decimalAdjustment(Number(val) || 0)
              .dividedBy(100)
              .toNumber(),
          )
        },
      } as typeof props)
    : {}
  return (
    <InputNumber decimalPlaces={7} addonAfter="%" {...props} {...newProps} />
  )
}
InputNumber.MultiplicationRatio = function InputNumberMultiplicationRatio(
  props: Parameters<typeof InputNumber>[0],
) {
  const searchContract = useSearchContractMaster({
    enabled:
      (props?.decimal ?? true) && typeof props?.decimalPlaces !== 'number',
    refetchOnMount: false,
  })
  function renderAddOnAfter() {
    if (!isFinite(Number(props?.value)) && props?.asText) {
      return undefined
    }
    return props?.addonAfter ?? '%'
  }
  return (
    <InputNumber
      decimalPlaces={searchContract?.data?.ratio_decimal_places ?? 2}
      infinityValueFallback={'-'}
      {...props}
      addonAfter={renderAddOnAfter()}
    />
  )
}

InputNumber.Currency = function InputNumberCurrency(
  props: InputNumberProps & CurrencyHooksProps,
) {
  const { t } = useTranslation(['common'])
  const {
    controls = false,
    decimal = true,
    currencyId = JAPAN_CURRENCY_ID,
    decimalPlaces,
    defaultNaNValue,
    textAlign,
    roundingMethod,
    useRounding = true,
    asText = false,
    minimumFractionDigits = false,
    roundingMethodUsage,
    markNegativeValue = false,
    useContractDecimalPlaces = false,
    ...restProps
  } = props || {}
  const [{ focused, value: localValue }, setState] = useState({
    focused: false,
    value: restProps.value,
  })
  const setFocused = (v: boolean) =>
    setState((prev) => ({ ...prev, focused: v }))
  const setValue = (v: any) => setState((prev) => ({ ...prev, value: v }))

  const { stringFormat, parse, isLoading } = useCurrencyHooks({
    decimal,
    currencyId,
    decimalPlaces,
    defaultNaNValue,
    roundingMethod,
    useRounding,
    roundingMethodUsage: roundingMethodUsage,
    minimumFractionDigits: focused ? 0 : minimumFractionDigits,
    useContractDecimalPlaces,
  })

  const classNames = [
    props.className,
    `number-align-${textAlign || 'left'}`,
    controls && !props.disabled && !props.readOnly ? 'number-has-control' : '',
    `decimal-${decimal}`,
  ]
    .filter((x) => !!x)
    .join(' ')

  if (isLoading && !asText) {
    return (
      <AntInputNumber
        key={'ant-loading-input-number'}
        placeholder={props.disabled ? '' : (t('input_number') as string)}
        formatter={(val: any) =>
          val === '-' || !val ? val : stringFormat(val)
        }
        parser={parse}
        {...restProps}
        value={restProps?.value ?? localValue}
        className={classNames}
        disabled
      />
    )
  }
  if (asText) {
    const isNegative = ((Number(props.value) || 0) as number) < 0
    const valueAsText = decimal
      ? stringFormat(
          decimalAdjustment((props.value || 0) as number)
            .mul(markNegativeValue && isNegative ? -1 : 1)
            .toNumber(),
        )
      : undefined
    const textClassName = [
      'input-number-as-text',
      `number-align-${textAlign || 'left'}`,
      textAlign === 'right' ? `block text-right` : null,
      markNegativeValue && ((Number(props.value) || 0) as number) < 0
        ? 'negative-amount'
        : null,
    ]
      .filter((x) => !!x)
      .join(' ')
    return (
      <span className={textClassName}>
        {props.addonBefore ? (
          <span className="addon-before">{props.addonBefore}</span>
        ) : undefined}
        {typeof props.value !== 'number' ? defaultNaNValue ?? 0 : valueAsText}
        {props.addonAfter ? (
          <span className="addon-after">{props.addonAfter}</span>
        ) : undefined}
      </span>
    )
  }

  if (!focused && minimumFractionDigits) {
    return (
      <AntInputNumber
        key={'ant-not-focused-input-number'}
        placeholder={props.disabled ? '' : (t('input_number') as string)}
        formatter={
          decimal
            ? (val: any) => (val === '-' || !val ? val : stringFormat(val))
            : undefined
        }
        parser={decimal ? parse : undefined}
        {...restProps}
        value={restProps?.value ?? localValue}
        controls={controls}
        className={classNames}
        onFocus={(e) => {
          setFocused(true)
          restProps.onFocus?.(e)
        }}
      />
    )
  }
  return (
    <AntInputNumber
      autoFocus={!!minimumFractionDigits}
      placeholder={props.disabled ? '' : (t('input_number') as string)}
      formatter={
        decimal
          ? (val: any) => (val === '-' || !val ? val : stringFormat(val))
          : undefined
      }
      parser={decimal ? parse : undefined}
      {...restProps}
      value={restProps?.value ?? localValue}
      onChange={(...args) => {
        setValue(args[0])
        restProps.onChange?.(...args)
      }}
      controls={controls}
      className={classNames}
      onBlur={(e) => {
        if (minimumFractionDigits) {
          setFocused(false)
        }
        restProps.onBlur?.(e)
      }}
    />
  )
}
InputNumber.useCurrencyHooks = useCurrencyHooks
InputNumber.decimalAdjustment = decimalAdjustment
Input.Number = InputNumber
Input.MultiLanguage = MInput
