import { useQuery, UseQueryOptions } from 'react-query'

import { AxiosError } from 'axios'
import { BASE_API_URL } from 'constant'
import ApiCall from 'services/ApiCall'

import useUrlQuery, { UseUrlQueryOptions } from 'helpers/QueryUrl/useUrlQuery'

export interface UseCurrencies {
  id: number
  name: string
  symbol: string
  rate: number
  sequence: number
  decimal_places: number

  //helpers
  display_decimal_digits: number
}

type UseMaterialResult = {
  result: {
    records: UseCurrencies[]
    length: number
  }
}

type TQueryFnData = UseMaterialResult
type TError = AxiosError

function useCurrency(
  urlOptions?: UseUrlQueryOptions & {
    field?: string[]
    sort?: any
    domain?: any[]
  },
  options?: UseQueryOptions<TQueryFnData, TError>,
) {
  const urlQuery = useUrlQuery(urlOptions)

  const params = {
    domain: urlOptions?.domain,
    field: urlOptions?.field,
    sort: urlOptions?.sort,
  }

  const query = useQuery<TQueryFnData, TError>(
    urlQuery.transformKey(['/get-all-currencies', params]),
    () =>
      ApiCall.api
        .post(
          urlQuery.transformUrl(
            `${BASE_API_URL}/dataset/res.currency/search_read`,
          ),
          {
            params: {
              domain: [...(urlOptions?.domain || [])],
              fields: urlOptions?.field || ['name', 'symbol', 'rate'],
              limit: 80,
              sort: urlOptions?.sort || 'sequence asc',
            },
          },
        )
        .then((res) => {
          return res.data
        }),
    {
      ...options,
    },
  )

  return {
    ...query,
    data: query.data?.result?.records,
    length: query.data?.result?.length,
    helpers: urlQuery,
  }
}

export default useCurrency
