import React from 'react'
import { useTranslation } from 'react-i18next'

import { InputNumber as AntInputNumber, InputNumberProps, Tooltip } from 'antd'

import { formatCurrency } from 'utils/helpers/formatter'
import { inputParserNumber } from 'utils/helpers/parser'

type Props = InputNumberProps & TooltipInputProps
const InputNumber: React.FC<Props> = ({
  errorMessage,
  showErrorMessage,
  className,
  ...props
}): JSX.Element => {
  const { t } = useTranslation()

  return (
    <Tooltip
      title={errorMessage}
      visible={showErrorMessage}
      color="red"
      destroyTooltipOnHide
    >
      <AntInputNumber
        formatter={formatCurrency}
        parser={inputParserNumber}
        placeholder={t('common:input_number')}
        className={`w-full ${className}`}
        {...props}
      />
    </Tooltip>
  )
}

export default InputNumber
