import getColumnTitleWidth from './getColumnTitleWidth'
import getTextAlignment from './getTextAlignment'
import getTextWidth from './getTextWidth'
import getTitleName from './getTitleName'
import renderColumn from './renderColumn'
import sorterString from './sorterString'

const MULTIPLY_WIDTH = 1.2621199671322925
export {
  getColumnTitleWidth,
  getTextAlignment,
  getTextWidth,
  getTitleName,
  MULTIPLY_WIDTH,
  renderColumn,
  sorterString,
}
