import React, { MouseEvent } from 'react'
import { useTranslation } from 'react-i18next'

import { PushpinOutlined } from '@ant-design/icons'
import MinusCircleOutlined from '@ant-design/icons/MinusCircleOutlined'
import { Space, Tooltip } from 'antd'
import { ColumnTitle } from 'antd/lib/table/interface'

import { ExtendedLabel } from 'components/ExtendedLabel'

type Props = {
  index: string
  title: ColumnTitle<any>
  required?: boolean
  fixed?: boolean
  onDelete: (key: string) => void
  canDeleteRow?: boolean
  // select?: boolean
  // customSectionTitle?: string
  // onSelectAll?: (value: any) => void
  // onReleaseAll?: (value: any) => void
  onPin?: (key: string) => void
  headerKey?: string
  showPin?: boolean
  dataIndex?: string
}

const MinusStyle = (fixed?: boolean): React.CSSProperties => ({
  fontSize: 20,
  transform: fixed ? 'rotate(-45deg)' : 'rotate(0)',
})
const HeaderTitle: React.FC<Props> = ({
  index,
  title,
  required,
  fixed,
  onDelete,
  canDeleteRow = true,
  // select,
  // onSelectAll,
  // onReleaseAll,
  // customSectionTitle = '',
  onPin,
  showPin = true,
  headerKey,
  dataIndex,
}) => {
  const { t } = useTranslation('translation')
  const handleClickPin = (event: MouseEvent) => {
    event.preventDefault()
    onPin(index)
  }

  const handleClickRemove = (event: MouseEvent) => {
    event.preventDefault()
    onDelete(index)
  }

  // if (select) {
  //   return (
  //     <Space>
  //       {customSectionTitle ? (
  //         <Tooltip placement="topLeft" title={customSectionTitle}>
  //           {t(`translation:${customSectionTitle}`)}
  //         </Tooltip>
  //       ) : (
  //         <React.Fragment>
  //           <Button ghost onClick={onSelectAll}>
  //             {t('translation:select_all')}
  //           </Button>
  //           <Button ghost onClick={onReleaseAll}>
  //             {t('translation:release_all')}
  //           </Button>
  //         </React.Fragment>
  //       )}
  //     </Space>
  //   )
  // }

  return (
    <div
      data-cy={`header-${dataIndex}`}
      className={`TableHeaderTitleContainer ${
        fixed && 'TableHeaderTitleFixedContainer'
      } ${headerKey === 'seasons-planning' ? 'TableHeaderSeasons' : ''}`}
    >
      <span className="ant-table-column-title">
        <Tooltip placement="topLeft" title={title}>
          <ExtendedLabel
            title={t(title as string)}
            textColor="white"
            requiredMark={required}
          />
        </Tooltip>
      </span>
      {index !== 'select_action' && (
        <Space direction="horizontal">
          {index !== 'remove_row' &&
            showPin &&
            headerKey !== 'seasons-planning' && (
              <PushpinOutlined
                style={MinusStyle(fixed)}
                onClick={handleClickPin}
              />
            )}
          {!fixed && canDeleteRow && headerKey !== 'seasons-planning' && (
            <MinusCircleOutlined
              style={MinusStyle(fixed)}
              onClick={handleClickRemove}
            />
          )}
        </Space>
      )}
      <style jsx>
        {`
          .TableHeaderTitleContainer {
            display: flex;
            justify-content: space-between;
            align-items: center;
            text-align: left;
          }
          .TableHeaderSeasons {
            text-align: center;
          }
        `}
      </style>
    </div>
  )
}

export default React.memo(HeaderTitle)
