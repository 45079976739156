import { FC, useEffect } from 'react'

import moment from 'moment'
import shallow from 'zustand/shallow'

import { DateConfig } from 'components/Templates/Input/services/formatter'
import useClosingHistory from 'views/InventoryControl/ClosingProcessSetting/hooks/useClosingHistory'
import useAccountClosingStore from 'views/InventoryControl/ClosingProcessSetting/stores/accountClosingStore'

export const ClosingProcessSettingProvider: FC = ({ children }) => {
  const { setClosedDate, setCurrentMonth, setIsClosed } =
    useAccountClosingStore(
      ({ setFiscalYear, setClosedDate, setCurrentMonth, setIsClosed }) => ({
        setFiscalYear,
        setClosedDate,
        setCurrentMonth,
        setIsClosed,
      }),
      shallow,
    )

  const { data, isSuccess, isFetching } = useClosingHistory(
    {
      limit: 1,
      fields: ['action_state', 'period_lock_date'],
      sort: 'write_date desc',
    },
    {
      refetchOnMount: true,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    },
  )

  useEffect(() => {
    if (isSuccess) {
      const result = data?.[0]
      const actionClose = result?.action_state === 'close'
      const periodLockDate = DateConfig.utcToLocalDate(result?.period_lock_date)

      setIsClosed(actionClose)
      setClosedDate(actionClose ? periodLockDate : null)
      setCurrentMonth(
        actionClose ? periodLockDate : moment().subtract(1, 'day'),
      )
    }
  }, [isSuccess, isFetching])

  return <>{children}</>
}
