/**
 * only done and draft
 * https://l-dx.slack.com/archives/C028VGY6YV9/p1699397575570309
 */
const ARRIVAL_STATUS = ['done', 'draft']
const SHIPMENT_FROM_CLASSIFICATION = [
  'factory',
  'supplier',
  'store',
  'customer',
  'warehouse',
]
const TRANSPORTATION_METHOD = ['air', 'ship_fcl', 'ship_lcl', 'courier']

export { ARRIVAL_STATUS, SHIPMENT_FROM_CLASSIFICATION, TRANSPORTATION_METHOD }

export const FORMAT_DATE = 'YYYY/MM/DD'
export const FORMAT_DATETIME = 'YYYY/MM/DD HH:mm:ss'
export const FORMAT_DATETIME_WITHOUT_SECOND = 'YYYY/MM/DD HH:mm'
export const FORMAT_BACKEND_DATE = 'YYYY-MM-DD'
export const FORMAT_BACKEND_DATETIME = 'YYYY-MM-DD HH:mm:ss'
export const FORMAT_BACKEND_DATETIME_WITHOUT_SECOND = 'YYYY-MM-DD HH:mm'

export const ADJUSTMENT_TYPE = ['transfer', 'tax', 'others']

export const TRANSACTION_TYPE = {
  PAYMENT: 'payment',
  ADVANCE_PAYMENT: 'advance_payment',
  REFUND: 'in_refund',
  REFUND_SALES: 'out_refund',
  TRANSFERRED: 'transferred',
  TRANSFER_FEE: 'transfer',
  PURCHASE: 'in_invoice',
  ADJUSTMENT: 'adjustment',
  SALES: 'out_invoice',
}

export const JAPAN_CURRENCY_ID = 25

export const JAPAN_COUNTRY_ID = 113

export const strToFixed = (
  value: string | number,
  tofixed = 2,
  disableFormat = false,
) => {
  if (!value) value = '0'
  let [intPart, decimalPart = ''] = `${value}`.split('.')
  decimalPart += '0'.repeat(Math.max(0, tofixed - decimalPart.length))

  if (!disableFormat) {
    intPart = intPart.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }

  return intPart + '.' + decimalPart.slice(0, tofixed)
}

export const blankValueList = (field: string) => {
  return [
    'proper_digestibility_amount_ratio',
    'proper_digestibility_amount',
    'proper_digestibility_qty',
    'final_digestibility_amount_ratio',
    'final_digestibility_qty',
    'purchase_amount',
    'purchase_qty',
    'turnover_rate_amount',
    'turnover_rate_qty',
    'end_of_period_inventory_qty',
    'end_of_period_inventory_amount',
    'beginning_of_period_inventory_amount',
    'beginning_of_period_inventory_qty',
    'inventory_amount',
    'inventory_amount_ratio',
    'inventory_qty',
    'inventory_qty_ratio',
    'actual_inventory_turnover',
    'current_inventory_amount',
    'current_inventory_quantity',
  ].includes(field)
}
