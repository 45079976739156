import { createContext, FC, Fragment, useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useRouter } from 'next/router'

import { Descriptions, Form, Input } from 'antd'
import { compact } from 'lodash'

import useCheckInstalledModule from 'data/useCheckInstalledModule'

import { FInputNumberDecimal } from 'components/fields'
import { getTextWidth } from 'components/TableExtended'

import { PosConfig } from '../../config'

const initialState: PosConfig = {
  pos_name: '',
  link_shipping_detail_id: '',
  link_arrival_detail_id: '',
  link_shipping_id: '',
  link_arrival_id: '',
  link_product_id: '',

  link_pos_arrival_id: '',
  link_pos_shipping_id: '',

  link_store_id: '',
  inventory_quantity: '',
  layaway_quantity: '',
  link_loss_id: '',

  pos_store_id: '',
  contract_id: '',
  send_url: '',
}
const PosContext = createContext<PosConfig>(initialState)

type PosContextProviderProps = {
  pos?: keyof typeof PosConfig
}

/**
 * It takes a `pos` prop and returns a `PosContext.Provider` with the value of the `pos` prop
 * @param  - `children` - The component that will be wrapped by the provider.
 * @param  - `pos` - The pos config to be used by the provider (ex. smaregi).
 */
const PosConfigProvider: FC<PosContextProviderProps> = ({
  children,
  pos = 'smaregi',
}) => {
  const qCheckInstalledModule = useCheckInstalledModule([
    'external_link',
    'ext_poscm',
  ])
  const externalLink = qCheckInstalledModule?.data?.externalLink
  const config = useMemo(() => {
    return externalLink ? PosConfig[pos] : initialState
  }, [externalLink])

  return (
    <PosContext.Provider value={{ ...config, pos_name: pos }}>
      {children}
    </PosContext.Provider>
  )
}

export default PosConfigProvider

export const usePosContext = () => {
  const context = useContext(PosContext)
  if (context === undefined) {
    throw new Error('usePosContext must be used within a PosContextProvider')
  }
  return context
}

export const usePosConfig = () => {
  const qCheckInstalledModule = useCheckInstalledModule([
    'external_link',
    'ext_poscm',
  ])
  const externalLink = qCheckInstalledModule?.data?.externalLink
  const fields = usePosContext()
  const router = useRouter()

  const isNewRegistration =
    !router.query?.id || router.query?.action === 'duplicate'

  const { t } = useTranslation()

  const title =
    (externalLink && {
      [fields.link_shipping_detail_id]: t('common:link_outbound_detail'),
      [fields.link_shipping_id]: t('common:link_outbound'),
      [fields.link_arrival_detail_id]: t('common:link_inbound_detail'),
      [fields.link_arrival_id]: t('common:link_inbound'),
      [fields.link_loss_id]: t('common:link_inbound'),
    }) ||
    {}

  const posColumns = useMemo(() => {
    if (!isNewRegistration && externalLink) {
      return [
        fields.link_shipping_detail_id,
        fields.link_arrival_detail_id,
        // fields.link_loss_id,
      ].map((field) => ({
        dataIndex: field,
        key: field,
        title: title[field],
        headerName: title[field],
        width: getTextWidth(title[field]),
      }))
    }

    return []
  }, [fields, externalLink])

  const posFormikFields = !isNewRegistration && externalLink && (
    <Fragment>
      <Descriptions size="small" bordered>
        <Descriptions.Item label={title[fields.link_shipping_id]}>
          <FInputNumberDecimal
            name={fields.link_shipping_id}
            readOnly
            bordered={false}
          />
        </Descriptions.Item>
      </Descriptions>

      <Descriptions size="small" bordered>
        <Descriptions.Item label={title[fields.link_loss_id]}>
          <FInputNumberDecimal
            name={fields.link_arrival_id}
            readOnly
            bordered={false}
          />
        </Descriptions.Item>
      </Descriptions>
    </Fragment>
  )

  const posAntFormField = !isNewRegistration && externalLink && (
    <Fragment>
      <Form.Item
        name={fields.link_shipping_id}
        label={title[fields.link_shipping_id]}
      >
        <Input placeholder="" readOnly bordered={false} />
      </Form.Item>
      <Form.Item
        name={fields.link_arrival_id}
        label={title[fields.link_arrival_id]}
      >
        <Input placeholder="" readOnly bordered={false} />
      </Form.Item>
    </Fragment>
  )

  const spreadFields = (field: { [key: string]: string }) => {
    return (
      compact(Object.keys(field))
        .map((key) => ({
          key,
          value: compact(Object.keys(field))[key],
        }))
        .reduce(
          (acc, curr) => ({
            ...acc,
            [curr.key]: curr.value,
          }),
          {},
        ) || {}
    )
  }

  return {
    ...fields,
    posColumns,
    posFormikFields,
    posAntFormField,
    spreadFields,
  }
}
