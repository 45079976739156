import { CSSProperties } from 'react'

import { Typography } from 'antd'
import { TextProps } from 'antd/lib/typography/Text'

const { Text: CurText } = Typography

const mapColor = {
  white: 'white',
  black: 'black',
  gray: '#2a2a2a',
  red: 'red',
  green: '#27AE60',
  primary: '#1B5292',
  blue: 'rgb(4, 34, 136)',
}

// const mapFontFamily = {
//   bold: 'SFProText-Bold',
//   regular: 'SFProText-Regular',
// }

interface IText extends TextProps {
  className?: string
  size?: number
  color?: 'primary' | 'white' | 'black' | 'gray' | 'red' | 'green' | any
  bold?: boolean
  align?:
    | 'center'
    | 'end'
    | 'justify'
    | 'left'
    | 'match-parent'
    | 'right'
    | 'start'
  style?: CSSProperties
  fontFamily?: 'bold' | 'regular'
  block?: boolean
  [key: string]: any
}

function Text(props: IText) {
  const {
    size = '16',
    color = 'rgb(4, 34, 136)',
    align,
    bold,
    block,
    style,
    // fontFamily = 'regular',
    ...cProps
  } = props

  const weight = bold ? 'bold' : 'normal'

  return (
    <CurText
      style={{
        // fontFamily: mapFontFamily[fontFamily],
        color: mapColor[color] || color,
        fontSize: size,
        fontWeight: weight,
        ...(align ? { textAlign: align, display: 'block' } : {}),
        ...(block ? { width: '100%', display: 'block' } : {}),
        ...style,
      }}
      {...cProps}
    />
  )
}

export default Text
