import { notification } from 'antd'
import { RcFile } from 'antd/lib/upload'
import i18n from 'i18next'

import packageJson from '../../package.json'

export { packageJson }

/**
 * @deprecated Use `parseNumber` instead.
 */
export function ensureReturnNumber(v, defaultValue: any = 0) {
  return typeof v === 'number' && Number.isFinite(v) ? v : defaultValue
}

/**
 * Convert the value to always returning number.
 *
 * Support currency number "50,000" -> 50000
 *
 * All invalid number will be converted to `0` like:
 * - `NaN` to `0`
 * - `Infinity` to `0`
 * - `null` to `0`
 * - `undefined` to `0`
 * - `-0` or `-0.00` to `0`
 * - `''` to `0`
 * - `'any string'` to `0`
 */
export function parseNumber(v, toFixed?: number) {
  const parsed = parseFloat(`${v}`.replace(/,/g, ''))
  /**
   * Validate & Convert `-0` or `-0.00` to `0`
   */
  const positifNum = Math.abs(parsed)
  let fixedNum: string | number =
    Number.isFinite(parsed) && positifNum !== 0 ? parsed : 0

  if (Number.isFinite(toFixed) && Math.ceil(toFixed) >= 0) {
    fixedNum = fixedNum.toFixed(toFixed)
  }

  return parseFloat(`${fixedNum}`)
}
parseNumber.safe = (...args: Parameters<typeof parseNumber>) => {
  try {
    return parseNumber(...args) || 0
  } catch (e) {
    return 0
  }
}

/**
 * Ex:
 * ```js
 * const sampleObj = {
 *   sales: 60, // -> 60
 *   avgSales: NaN, // -> 0
 *   discount: Infinity, // -> 0
 * }
 * ```
 * See more detail abount `parseNumber` util
 */
export function parseNumberObjectValues(obj): Record<string, any> {
  const modObj = {}
  Object.keys(obj).forEach((key) => {
    modObj[key] = parseNumber(obj[key])
  })
  return modObj
}

/**
 * @deprecated Use `parseNumber` instead
 *
 * v = `Infinity` -> true
 *
 * v = `-0` -> true
 *
 * v = `0` -> true
 *
 * v = `NaN` -> true
 *
 * v = `null` -> true
 *
 * v = `undefined` -> true
 *
 * @param toFixed `.toFixed` the value and convert to positif number using
 * `Math.abs` to ensure the value is zero (Javascript can't validate `-0`).
 * So `0.0 === 0` is `true`
 */
export function isZero(v, toFixed?: any) {
  const ensuredValue = parseFloat(v)

  if (Number.isFinite(parseFloat(toFixed))) {
    return (
      !Number.isFinite(ensuredValue) ||
      Math.abs(parseFloat(ensuredValue.toFixed(toFixed))) === 0
    )
  }

  return !Number.isFinite(ensuredValue) || Math.abs(ensuredValue) === 0
}

/**
 * Check file size before upload
 *
 * @param file File object
 * @param fileSize Max file size (default: 10MB)
 * @returns boolean
 */
export const checkFileLimit = (file: RcFile, fileSize = 10) => {
  const isLt10M = file.size / 1024 / 1024 < fileSize
  if (!isLt10M) {
    notification.error({
      message: i18n?.t('common:error_file_size_limit'),
    })
    return true
  }

  return false
}
