import { parseNumber } from 'utils'

const formatNumber = (number: any, toFixed?: any): string => {
  return new Intl.NumberFormat('ja-JP').format(parseNumber(number, toFixed))
}

/**
 * @deprecated
 * Please use formatNumber from utils/helpers/formatter
 *
 * @param number
 * @returns
 */
const formatCurrency = (string: string | number, toFixed?: any) => {
  if (Number.isFinite(toFixed)) {
    return formatNumber(parseFloat(String(string)).toFixed(toFixed))
  }

  return formatNumber(string)
}

const formatRoundingMethod = (
  amount: any,
  method: string,
  decimalPlace: number,
): number => {
  const fixNum = parseNumber(amount)
  const decimalPlacePow = Math.pow(10, decimalPlace || 0)
  const amountPow = parseNumber(Math.abs(fixNum * decimalPlacePow), 5)

  const negativeSign = fixNum < 0 ? -1 : 1
  const mapMethod = {
    round_up: (Math.ceil(amountPow) * negativeSign) / decimalPlacePow,
    round_down: (Math.floor(amountPow) * negativeSign) / decimalPlacePow,
    rounding: (Math.round(amountPow) * negativeSign) / decimalPlacePow,
  }

  return mapMethod[method || 'rounding']
}

/**
 * @deprecated
 * Please use formatNumber from utils/helpers/formatter
 *
 * @param number
 * @returns
 */
const formatPercentage = (value: string) => value && `${value}%`

export { formatCurrency, formatNumber, formatPercentage, formatRoundingMethod }
