import { isNil } from 'lodash'
import type { Moment } from 'moment'
import moment from 'moment'

import utcToLocal from 'helpers/utcToLocal'
import {
  FORMAT_BACKEND_DATE,
  FORMAT_BACKEND_DATETIME,
  FORMAT_DATE,
  FORMAT_DATETIME,
} from 'utils/constants'

const formatDate = (
  date: string | Date | moment.Moment,
  defaultValue: any = '',
) => {
  return date ? moment(date).format(FORMAT_DATE) : defaultValue
}

/**
 * Format date from backend to local
 * @param date
 * @returns string | undefined
 */
const formatDateToLocal = (date: string | Date | moment.Moment) =>
  date ? moment(utcToLocal(date)).format(FORMAT_DATE) : undefined

/**
 * Format date from backend to local
 * @param date
 * @returns string | undefined
 */
const formatDateTimeToLocal = (date: string | Date | moment.Moment) =>
  date ? moment(utcToLocal(date)).format(FORMAT_DATETIME) : undefined

/**
 * Format date from backend to local moment
 * @param date
 * @returns moment.Moment | undefined
 */
const formatDateToMomentLocal = (date: string) =>
  date ? moment(utcToLocal(date)) : undefined

/**
 * Format date from backend
 * @param date
 * @returns string
 */
const formatDateTime = (date: string | Date | moment.Moment) => {
  return moment(date).format(FORMAT_DATETIME)
}

const formatTime = (time: string, format = 'HH:mm:ss') => {
  return moment(time, format)
}

function parse(date: string | moment.Moment | null | undefined) {
  if (isNil(date)) {
    return date
  }
  return moment.isMoment(date) ? date : moment(date)
}

/**
 * Send date to server in format yyyy-mm-dd with timezone
 * @param date
 * @returns
 */
const convertToUtc = (date?: string) => {
  if (isNil(date)) return null
  return moment(date).format('YYYY-MM-DD HH:mm:ssZ')
}

/**
 * Send date to server in format yyyy-mm-dd
 * @param date
 * @returns
 */
const convertToDate = (date?: string | Moment) => {
  if (isNil(date)) return null
  return moment(date).format(FORMAT_BACKEND_DATE)
}

const convertToDateUtc = (date?: string | Moment) => {
  if (isNil(date)) return null
  const convertedDate = moment(date).format(`${FORMAT_BACKEND_DATE} 00:00:00`)
  return moment(convertedDate).utc().format(FORMAT_BACKEND_DATETIME)
}

export default {
  formatDate,
  formatDateToLocal,
  formatDateTimeToLocal,
  formatDateTime,
  parse,
  convertToUtc,
  convertToDate,
  formatDateToMomentLocal,
  formatTime,
  convertToDateUtc,
}
